import React from 'react'
import HeaderMain from 'components/UI/Headers/HeaderMain/HeaderMain'
import NavigationMain from 'components/UI/NavigationMain/NavigationMain'
import FooterMain from 'components/UI/FooterMain/FooterMain'
import HeaderPage from 'components/UI/Headers/HeaderPage/HeaderPage'
import BreadcrumbsMain from 'components/UI/Breadcrums/BreadcrumsMain/BreadcrumsMain'
import CardsContainer from 'components/UI/Containers/CardsContainer/CardsContainer'
import StoryCard from 'components/UI/Cards/StoryCard/StoryCard'
import useArticlesAndBrochures from './hooks/useArticlesAndBrochures'
import styles from './styles.module.scss'
const ArticlesAndBrochures = () => {
    const { breadcrumbsData, pageTheme, pageData, articles } = useArticlesAndBrochures()
    const { title, summary } = pageData
    return (
        <div className={styles.layout}>
            <HeaderMain />
            <NavigationMain />
            <main>
                <HeaderPage title={title} summary={summary} theme={pageTheme} />
                <BreadcrumbsMain items={breadcrumbsData} />
                <CardsContainer
                    aspectRatio='3/2'
                    cols={2}
                    component={<StoryCard />}
                    items={articles.slice(0, 2)}
                />
                <CardsContainer
                    aspectRatio='16/9'
                    cols={3}
                    component={<StoryCard />}
                    items={articles.slice(2)}
                />
            </main>
            <FooterMain />
        </div>
    )
}

export default ArticlesAndBrochures
