import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { setIncorporationRequestService } from 'services/setAutorizationRequestService'
import inputValidate from 'utils/inputValidate'

const useFormInformedConsent = () => {
    const navigate = useNavigate()
    const [isFetching, setIsFetching] = useState(false)
    const initialFormState = {
        patient: {
            names: '',
            surnames: '',
            email: '',
            mobile: '',
            product: '',
            therapeuticIndication: '',
        },
        professional: {
            names: '',
            surnames: '',
            email: '',
            specialty: '',
            professionalRegistration: '',
        },
        errors: {
            patient: {
                names: false,
                surnames: false,
                email: false,
                mobile: false,
                product: false,
                therapeuticIndication: false,
            },
            professional: {
                names: false,
                surnames: false,
                email: false,
                specialty: false,
                professionalRegistration: false,
            },
        },
    }
    const [formState, setFormState] = useState({ ...initialFormState })

    //* Handle Input Change
    const handleInputChange = (inputName, inputValue, fieldset, minLength) => {
        const validationResult = inputValidate({
            inputName,
            inputValue,
            minLength,
        })
        const newFormState = {
            patient:
                fieldset === 'patient'
                    ? { ...formState.patient, [inputName]: inputValue }
                    : { ...formState.patient },
            professional:
                fieldset === 'professional'
                    ? { ...formState.professional, [inputName]: inputValue }
                    : { ...formState.professional },
            errors: {
                ...formState.errors,
                [fieldset]: {
                    ...formState.errors[fieldset],
                    [inputName]: validationResult,
                },
            },
        }
        setFormState({ ...newFormState })
    }

    //* Handle Reset Form
    const handleResetForm = () => {
        /* setFormState({ ...initialFormState })
        const formElement = document.getElementById('autorizationRequestForm')
        formElement.reset() */
    }

    //* Handle Submit
    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsFetching(true)
        const errors = []
        const patientErrors = formState.errors.patient
        Object.keys(formState.patient).forEach((input) => {
            if (formState.errors.patient[input]) {
                errors.push(`patient_${input}`)
                /* const value = formState.patient[input]
                if (value.trim() === '') {
                    patientErrors[input] = 'Debe completar este campo'
                    errors.push(input)
                } */
            }
        })

        const professionalErrors = formState.errors.professional
        Object.keys(formState.professional).forEach((input) => {
            if (formState.errors.professional[input]) {
                errors.push(`professional_${input}`)
            }
            /* const value = formState.professional[input]
            if (value.trim() === '') {
                professionalErrors[input] = 'Debe completar este campo'
                errors.push(input)
            } */
        })

        const newFormState = {
            ...formState,
            errors: {
                ...formState.errors,
                patient: {
                    ...patientErrors,
                },
                professional: {
                    ...professionalErrors,
                },
            },
        }

        setFormState({ ...newFormState })
        handleResetForm()

        if (errors.length > 0) {
            setIsFetching(false)
        } else {
            const result = await setIncorporationRequestService({
                patient: { ...formState.patient },
                professional: { ...formState.professional },
            })
            if (result.status) {
                console.log(formState)
                setTimeout(function () {
                    navigate('/')
                }, 3000)
            }
            setIsFetching(false)
        }
    }

    return {
        patientData: formState.patient,
        professionalData: formState.professional,
        errors: formState.errors,
        isFetching,
        handleInputChange,
        handleSubmit,
    }
}

export default useFormInformedConsent
