//# Authentication Reducers

import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_VALIDATE } from '../constants/auth'
const initialState = { user: null, token: null }

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_LOGIN:
            return {
                ...state,
                ...action.payload,
            }
        case AUTH_LOGOUT:
            return {
                ...action.payload,
            }
        case AUTH_VALIDATE:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state
    }
}
