import React from 'react'
import { Link } from 'react-router-dom'
import { BackgroundImage } from '@mantine/core'
import styles from './styles.module.scss'

const StoryCard = ({ aspectRatio, config, data }) => {
    const { navigation } = config
    const { level, slug: linkUrl } = navigation
    const { metaDescription, title, links } = data
    const { src: imageSrc } = data.media.coverImage
    const link = () => {
        if (links && links.length === 1 && links[0].isDirect === true) {
            return links[0].url
        }
        return level === 0 ? `/${linkUrl}` : `./${linkUrl}`
    }

    return (
        <Link
            style={{
                textDecoration: 'none',
                '&hover': { textDecoration: 'none' },
            }}
            to={link()}
            target={link && link.isBlank ? '_blank' : '_self'}
        >
            <div className={styles.container}>
                <BackgroundImage src={imageSrc} sx={{ aspectRatio }} />
                <div className={styles.infoWrapper}>
                    <div className={styles.separator}></div>
                    {metaDescription && (
                        <div className={styles.metaDescription}>
                            {metaDescription}
                        </div>
                    )}
                    <div className={styles.title}>{title}</div>
                </div>
            </div>
        </Link>
    )
}

export default StoryCard
