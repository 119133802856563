import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Logo from 'components/UI/Logo/Logo'
import styles from './styles.module.scss'

const HeaderMain = () => {
    const { colorScheme } = useSelector((state) => state.settings)
    return (
        <div className={styles.container}>
            <header>
                <div className={styles.projectLogoContainer}>
                    <Link to={'/'}>
                        <Logo logo='bmsLogo' theme={colorScheme} />
                    </Link>
                </div>
                <div className={styles.programLogoContainer}>
                    <Logo logo='programLogo' theme={colorScheme} />
                </div>
            </header>
        </div>
    )
}

export default HeaderMain
