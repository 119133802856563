//# Navigation actions
import { GET_NAVIGATION } from '../constants/navigation'

//* Settings set color scheme
export const getNavigation = (payload) => {
    return async function (dispatch) {
        dispatch({
            type: GET_NAVIGATION,
            payload: payload,
        })
    }
}
