import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { Avatar } from '@mantine/core'
import styles from './styles.module.scss'

const ContactCard = ({ data }) => {
    const { media, title, subtitle, summary } = data.data || data

    const { coverImage } = media
    return (
        <div className={styles.contactCard}>
            <Avatar
                radius='sm'
                src={coverImage.src}
                bg='lightChocolateWash'
                size='lg'
            />
            <div className={styles.info}>
                <div className={styles.title}>{title}</div>
                <div className={styles.subtitle}>{parse(subtitle || '')}</div>
                <div className={styles.summary}>{parse(summary) || ''}</div>
            </div>
        </div>
    )
}

export default ContactCard

ContactCard.propTypes = {
    coverImage: PropTypes.string,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    summary: PropTypes.string,
}

ContactCard.defaultProps = {
    coverimage: 'Link label',
    title: 'Contact Card Title',
    subtitle: 'Contact card subtitle.',
    summary: 'Contact card summary.',
}
