const inputValidate = ({
    inputName,
    inputValue,
    minLength = 2,
    errorMessage = null,
}) => {
    switch (inputName) {
        case 'names':
        case 'professionalRegistration':
        case 'surnames':
            if (validateNotEmpty(inputValue)) {
                return 'Debe completar este campo'
            }
            if (validateMinLength(inputValue, minLength)) {
                return `Este campo debe tener un minimo de ${minLength} caracteres`
            }
            return false

        case 'email':
            if (validateNotEmpty(inputValue)) {
                return 'Debe completar este campo'
            }
            if (validateIsEmail(inputValue)) {
                return 'Debe ingresar un correo valido'
            }
            return false

        case 'mobile':
        case 'phone':
            if (validateNotEmpty(inputValue)) {
                return 'Debe completar este campo'
            }
            if (validateIsNumber(inputValue)) {
                return 'Debe ingresar solo caracteres de tipo numerico'
            }
            if (validateMinLength(inputValue, minLength)) {
                return `Este campo debe tener un minimo de ${minLength} caracteres`
            }
            return false

        case 'product':
        case 'terapeuthicIndication':
            if (validateNotEmpty(inputValue)) {
                return 'Debe seleccionar una opción'
            }
            return false

        default:
            return false
    }
}

export default inputValidate

const validateNotEmpty = (inputValue) => (inputValue.length > 0 ? false : true)

const validateMinLength = (inputValue, minLength) =>
    inputValue.length >= minLength ? false : true

const validateIsEmail = (inputValue) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue) ? false : true

const validateIsNumber = (inputValue) =>
    /^[0-9]+$/.test(inputValue) ? false : true
