export const getPageData = (pages, url) => {
    return pages.length > 0
        ? pages.find((page) => page.data.slug === url)
        : null
}

export const getBreadcrumsData = (params, posts) => {
    const getPathLevel = (index, url) => {
        if (index === 2) return `#`
        if (index === 1) return `#`
        return `/${url}`
    }
    return [
        { title: 'inicio', url: '/inicio' },
        ...posts
            .filter((post) => Object.values(params).includes(post.url))
            .map((post, index) => {
                return {
                    active:
                        index + 1 === Object.values(params).length
                            ? true
                            : false,
                    title: post.title,
                    url: getPathLevel(index, post.url),
                }
            }),
    ]
}
export const getPageTheme = (params) => {
    const { url1 } = params
    if (url1 === 'sobre-el-programa') return 'themeAmber'
    if (url1 === 'sobre-mi-enfermedad') return 'themePeach'
    if (url1 === 'sitios-de-interes') return 'themeOlive'
    if (url1 === 'preguntas-frecuentes') return 'themeAlmond'
    if (url1 === 'mi-monitora') return 'themeLightChocolateWash'
}
/* export const getPageData = (data, params) => {
    const { url1, url2, url3 } = params
    const getCurrentPage = () => {
        if (url3) return url3
        if (url2) return url2
        return url1
    }
    return data.find((page) => page.url === getCurrentPage())
} */
export const getFeaturedPages = (pages, featuredPages) => {
    return pages
        .filter((page) => featuredPages.includes(page.id))
        .map((page) => {
            return {
                title: page.title,
                summary: page.summary,
                imageSource: page.media.coverImage.src,
                linkUrl: page.url,
                linkLabel: page.linkLabel || 'Más información >',
            }
        })
}

export const getFeaturedPosts = (posts, featuredPosts) => {
    return posts
        .filter((post) => featuredPosts.includes(post.id))
        .map((post) => {
            return {
                title: post.title,
                summary: post.summary,
                imageSource: post.media.coverImage.src,
                linkUrl: post.url,
                linkLabel: post.linkLabel || 'Más información >',
                metaDescription: post.metaDescription,
            }
        })
}
export const kebabCaseToCamelCase = (url) => {
    const result = url
        .split('-')
        .map((string, index) => {
            if (index === 0) return string
            return string[0].toUpperCase() + string.substring(1)
        })
        .join('')
    return result
}

export const getImageOrientation = (aspectRatio) => {
    const units = aspectRatio.trim().replace(/\s+/g, '').split('/')
    const result = units[0] / units[1]
    if (result < 1) return 'portrait'
    return 'landscape'
}

export const getCoverImage = (imageId, imageWidth) =>
    `https://images.pexels.com/photos/${imageId}/pexels-photo-${imageId}.jpeg?auto=compress&cs=tinysrgb&w=${imageWidth}&lazy=load`

export const generateAlphaColorPalette = (rgbColor, steps) => {
    const alphaValues = Array.from(
        { length: steps },
        (_, i) => (i + 1) / steps
    ).reverse()
    const palette = alphaValues.map((alpha) => `rgba(${rgbColor}, ${alpha})`)
    return palette
}

export const setGlobalFontSize = (globalFontSize) => {
    switch (globalFontSize) {
        case 'xs':
            return '90%'
        case 'sm':
            return '95%'
        case 'lg':
            return '105%'
        case 'xl':
            return '110%'
        default:
            return '100%'
    }
}

export const getValidationToken = () => {
    if (JSON.parse(localStorage.getItem('token')) === undefined) {
        return null
    }
    return JSON.parse(localStorage.getItem('token'))
}
