import React from 'react'

import CardsContainer from 'components/UI/Containers/CardsContainer/CardsContainer'
import FooterMain from 'components/UI/FooterMain/FooterMain'
import HeaderMain from 'components/UI/Headers/HeaderMain/HeaderMain'
import HeaderPage from 'components/UI/Headers/HeaderPage/HeaderPage'
import NavigationMain from 'components/UI/NavigationMain/NavigationMain'
import StoryCard from 'components/UI/Cards/StoryCard/StoryCard'

import useArticlesAndMaterials from './hooks/useArticlesAndMaterials'
import styles from './styles.module.scss'
const ArticlesAndMaterials = () => {
    const { pageData, articles } = useArticlesAndMaterials()
    return !pageData ? (
        <div>Loading</div>
    ) : (
        <div className={styles.layout}>
            <HeaderMain />
            <NavigationMain />
            <main>
                <HeaderPage
                    title={pageData.title}
                    summary={pageData.summary}
                    theme='themeAmber'
                />
                <CardsContainer
                    aspectRatio='16/9'
                    cols={4}
                    component={<StoryCard />}
                    items={articles}
                />
            </main>
            <FooterMain />
        </div>
    )
}

export default ArticlesAndMaterials
