const inputTextTheme = (mantineTheme, theme) => {
    const themes = {
        default: {
            label: {
                color: mantineTheme.colors.darkGrey[0],
                fontSize: mantineTheme.fontSizes.lg,
            },
            input: {
                borderRadius: mantineTheme.radius.sm,
                fontSize: mantineTheme.fontSizes.md,
                marginTop: mantineTheme.spacing.xs,
                '&::placeholder': { fontSize: mantineTheme.fontSizes.md },
                '&[data-invalid]': {
                    backgroundColor: mantineTheme.colors.danger[9],
                    border: `solid 1px ${mantineTheme.colors.danger[4]}`,
                    color: mantineTheme.colors.danger[2],
                    '&::placeholder': {
                        color: mantineTheme.colors.danger[6],
                    },
                },
            },
            error: {
                color: mantineTheme.colors.danger[4],
                fontSize: mantineTheme.fontSizes.sm,
                fontWeight: 'bold',
                marginTop: mantineTheme.spacing.xs,
            },
        },
        myMonitor: {
            root: {
                width: '100%',
            },
            label: {
                color: mantineTheme.colors.darkGrey[0],
                fontSize: mantineTheme.fontSizes.lg,
            },
            input: {
                borderStyle: 'none',
                fontSize: mantineTheme.fontSizes.md,
                borderRadius: mantineTheme.radius.sm,
                '&::placeholder': { fontSize: mantineTheme.fontSizes.md },
            },
            error: {
                fontSize: mantineTheme.fontSizes.sm,
                fontWeight: 'bold',
                marginTop: mantineTheme.spacing.xs,
            },
        },
    }
    return !theme ? themes.default : themes[theme]
}

export default inputTextTheme
