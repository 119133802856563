import { posts } from '../../../../data/appData'
import { useParams } from 'react-router-dom'
import { getBreadcrumsData } from 'utils/utils'
const useBreadcrums = () => {
    const params = useParams()
    const breadcrumbsData = getBreadcrumsData(params, posts)
    return {
        breadcrumbsData,
    }
}
export default useBreadcrums
