const rootStyles = { border: '0px' }

export const notificationPrimary = (theme) => {
    return {
        root: {
            ...rootStyles,
            borderRadius: theme.radius.sm,
            backgroundColor: theme.colors.blue[6],
            boxShadow: theme.shadows.xl,
            '::before': { backgroundColor: theme.colors.red[0] },
        },
        icon: {
            backgroundColor: 'white !important',
            color: `${theme.colors.blue[6]} !important`,
        },
        body: {},
        title: { color: '#fff', fontWeight: 'bold' },
        description: { color: 'rgba(255,255,255,0.9)' },
        closeButton: { color: 'white', ':hover': { color: '#c00' } },
    }
}

export const notificationSuccess = (theme) => {
    return {
        root: {
            ...rootStyles,
            borderRadius: theme.radius.sm,
            backgroundColor: theme.colors.green[6],
            boxShadow: theme.shadows.xl,
            '::before': { backgroundColor: theme.colors.red[0] },
        },
        icon: {
            backgroundColor: 'white !important',
            color: `${theme.colors.green[6]} !important`,
        },
        body: {},
        title: { color: '#fff', fontWeight: 'bold' },
        description: { color: 'rgba(255,255,255,0.9)' },
        closeButton: { color: 'white', ':hover': { color: '#c00' } },
    }
}

export const notificationWarning = (theme) => {
    return {
        root: {
            ...rootStyles,
            borderRadius: theme.radius.sm,
            backgroundColor: theme.colors.orange[7],
            boxShadow: theme.shadows.xl,
            '::before': { backgroundColor: theme.colors.red[0] },
        },
        icon: {
            backgroundColor: 'white !important',
            color: `${theme.colors.orange[7]} !important`,
        },
        body: {},
        title: { color: '#fff', fontWeight: 'bold' },
        description: { color: 'rgba(255,255,255,0.9)' },
        closeButton: { color: 'white', ':hover': { color: '#c00' } },
    }
}

export const notificationDanger = (theme) => {
    return {
        root: {
            ...rootStyles,
            borderRadius: theme.radius.sm,
            backgroundColor: '#c00',
            boxShadow: theme.shadows.xl,
            '::before': { backgroundColor: theme.colors.red[0] },
        },
        icon: { backgroundColor: 'white !important', color: '#c00 !important' },
        body: {},
        title: { color: '#fff', fontWeight: 'bold' },
        description: { color: 'rgba(255,255,255,0.9)' },
        closeButton: { color: 'white', ':hover': { color: '#c00' } },
    }
}
