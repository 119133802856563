import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from '@mantine/core'

const InputCheckbox = ({
    error,
    handleCheckboxChange,
    haslink,
    icon,
    initialValue,
    label,
    linkUrl,
    name,
    placeholder,
    required,
    theme,
    variant,
}) => {
    const [value, setValue] = useState(initialValue)

    useEffect(() => {
        handleCheckboxChange(value)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    return (
        <Checkbox
            error={error || false}
            label={label}
            name={name}
            onChange={() => setValue(!value)}
            styles={theme}
            value={false}
            variant={variant}
        />
    )
}

InputCheckbox.propTypes = {
    handleInputChange: PropTypes.func,
    icon: PropTypes.node,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    name: PropTypes.string,
    placeholder: PropTypes.string,
    theme: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
}

InputCheckbox.defaultProps = {
    handleInputChange: () => {},
    icon: null,
    label: 'Input Label',
    name: 'Input Name',
    placeholder: 'Input Placeholder',
    theme: {},
}

export default InputCheckbox
