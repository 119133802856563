import { showNotifications } from 'utils/showNotifications'

const apiUrl = process.env.REACT_APP_API_URL
const env = process.env.REACT_APP_ENV

//* Request Auth Validate
export async function setIncorporationRequestService(payload) {
    try {
        const route = 'incorporations'
        const endPoint = `${apiUrl}${route}`
        /* const headers = new Headers()
        headers.append('Content-Type', 'application/json, text/plain') */

        const headers = {
            Accept: 'application/json, text/plain',
            'Content-Type': 'application/json;charset=UTF-8',
            env: env,
        }

        const response = await fetch(`${endPoint}`, {
            headers: headers,
            body: JSON.stringify({ ...payload }),
            method: 'POST',
        })

        const result = await response.json()

        if (result.status) {
            showNotifications({
                type: 'success',
                title: 'Solicitud generada con exito',
                message: 'El proceso se ha completado de manera exitosa.',
            })
        } else {
            showNotifications({
                type: 'danger',
                title: 'Error',
                message: result.message,
            })
        }
        return { status: result.status }
    } catch (e) {
        console.log(e)
    }
}
