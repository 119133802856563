import { useEffect, useState } from 'react'
import { Button, Modal as MantineModal, useMantineTheme } from '@mantine/core'
import { buttonTheme } from 'assets/themes/buttons'
import modalTheme from 'assets/themes/modal'
const Modal = ({ closed, opened, close, handleClose, targetUrl }) => {
    const mantineTheme = useMantineTheme()
    const test = modalTheme(mantineTheme).default
    //const { onConfirm, handleClose, handleOpen, targetUrl } = useModal()

    if (closed) {
        return null
    }
    return (
        <MantineModal
            onClose={closed}
            opened={opened}
            close={close}
            withCloseButton={false}
            title='Atención'
            centered
            closeOnClickOutside={false}
            closeOnEscape={false}
            styles={test}
        >
            <div
                style={{
                    padding: mantineTheme.spacing.md,
                }}
            >
                <p>
                    Ahora está abandonando el sitio web de Bristol Myers Squibb
                    México
                </p>
                {/*                 <br />
                <p>
                    La información en los sitios de Bristol Myers Squibb fuera
                    de México solo es apropiado para residentes del país del
                    sitio seleccionado.
                </p> */}
            </div>
            <div
                style={{
                    borderTop: `solid 1px ${mantineTheme.colors.lightGrey[0]}`,
                    display: 'flex',
                    gap: '.5rem',
                    justifyContent: 'flex-end',
                    padding: mantineTheme.spacing.sm,
                }}
            >
                <a
                    href={targetUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={handleClose}
                >
                    <Button
                        variant='filled'
                        sx={{ borderRadius: '.25rem', fontWeight: 'normal' }}
                    >
                        Acepto
                    </Button>
                </a>

                <Button
                    variant='outlined'
                    sx={{
                        border: 'solid 1px purple',
                        borderRadius: '.25rem',
                        color: 'darkGrey',
                        fontWeight: 'normal',
                    }}
                    onClick={handleClose}
                >
                    Cancelar
                </Button>
            </div>
        </MantineModal>
    )
}

export default Modal
