import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Anchor, Alert, Button, Loader, Tabs } from '@mantine/core'
import { useMantineTheme, NavLink } from '@mantine/core'
import { IconAlertTriangleFilled } from '@tabler/icons-react'

import InputCheckbox from 'components/UI/Inputs/InputCheckbox/InputCheckbox'
import InputPassword from 'components/UI/Inputs/InputPassword/InputPassword'
import InputText from 'components/UI/Inputs/InputText/InputText'

import tabsTheme from 'assets/themes/tabsTheme'
import { navLinkLoginTheme } from 'assets/themes/links'

import useFormLogin from './hooks/useFormLogin'
import styles from './styles.module.scss'
const CheckboxLabel = () => {
    return (
        <>
            He leído y acepto la{' '}
            <Anchor href='/aviso-de-privacidad' color='purple.0'>
                Política de Privacidad
            </Anchor>{' '}
            y los{' '}
            <Anchor href='/terminos-y-condiciones' color='purple.0'>
                Términos y Condiciones
            </Anchor>{' '}
            del programa de apego al tratamiento de Bristol Myers Squibb.
        </>
    )
}
const FormLogin = () => {
    const [isOpen, setIsOpen] = useState(false)
    const handleClose = () => {
        setIsOpen(false)
    }
    const theme = useMantineTheme()
    const icon = <IconAlertTriangleFilled />
    const { loginForm } = tabsTheme(theme)
    const {
        activeTab,
        instanceCountryCode,
        isFetching,
        setActiveTab,
        handleCheckboxChange,
        handleInputChange,
        handleSubmit,
    } = useFormLogin()

    return (
        <div className={styles.container}>
            {instanceCountryCode === 'ar' ? (
                <Tabs
                    value={activeTab}
                    onTabChange={setActiveTab}
                    styles={loginForm}
                >
                    {isOpen && (
                        <Alert
                            color='danger.1'
                            icon={icon}
                            radius='sm'
                            title='Usuario incorrecto'
                            variant='filled'
                            withCloseButton
                            onClose={handleClose}
                        >
                            Lorem ipsum dolor sit, amet consectetur adipisicing
                            elit. At officiis, quae tempore necessitatibus
                            placeat saepe.
                        </Alert>
                    )}
                    <Tabs.List>
                        <Tabs.Tab value='patients'>Pacientes</Tabs.Tab>
                        <Tabs.Tab value='professionals'>
                            Profesionales de la salud
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value='patients'>
                        <form className={styles.formTabs}>
                            <InputText
                                handleInputChange={handleInputChange}
                                initialValue=''
                                label='Usuario'
                                name='username'
                                placeholder='Escriba aquí su nombre de usuario'
                            />
                            <InputPassword
                                color='purple'
                                handleInputChange={handleInputChange}
                                initialValue=''
                                label='Contraseña'
                                name='password'
                                placeholder='Escriba aquí su contraseña'
                            />
                            <InputCheckbox
                                handleCheckboxChange={handleCheckboxChange}
                                initialValue={false}
                                label={<CheckboxLabel />}
                                name='terms'
                            />
                            <Button variant='filled' onClick={handleSubmit}>
                                {isFetching ? (
                                    <Loader
                                        size='xs'
                                        color='rgba(255, 255, 255, 1)'
                                    />
                                ) : (
                                    'Iniciar Sesión'
                                )}
                            </Button>
                            <Button variant='outline' color='darkGrey'>
                                Recuperar contraseña
                            </Button>
                        </form>
                    </Tabs.Panel>
                    <Tabs.Panel value='professionals'>
                        <form className={styles.form}>
                            <div className={styles.disclaimer}>
                                (Consentimiento Informado)
                                <br />
                                <br />
                                Al acceder a esta secciòn usted declara conocer
                                que:
                                <br />
                                <br />
                                BMS Pacientes es un programa exclusivo dirigido
                                a pacientes con prescripción o en tratamiento
                                con medicamentos en indicaciones aprobadas de
                                Bristol Myers Squibb, diseñado para brindar
                                asistencia y orientación en salud con el
                                objetivo principal de colaborar con el médico
                                para mejorar el bienestar de los pacientes.
                                <br />
                                <br />
                                Los datos personales que usted brinde serán
                                utilizados exclusivamente para un primer
                                contacto, en el que se le presentará el programa
                                al paciente y se le darán instrucciones para ser
                                registrado. Por eso, es requisito indispensable
                                que usted obtenga el consentimiento voluntario
                                del paciente para que el Programa BMS Pacientes
                                de Bristol Myers Squibb trate y organice los
                                datos personales que obtenga como consecuencia
                                de esta registración.
                            </div>

                            <NavLink
                                label='Generar Solicitud de Incorporación'
                                component={Link}
                                to='/consentimiento-informado'
                                variant='subtle'
                                styles={navLinkLoginTheme}
                            />

                            {/* <InputText
                            label='Usuario'
                            placeholder='Escriba aquí su nombre de usuario'
                        />
                        <InputPassword
                            label='Contraseña'
                            placeholder='Escriba aquí su contraseña'
                        />
                        <InputCheckbox label='He leído y acepto la Política de Privacidad y los Términos y Condiciones del programa de apego al tratamiento de Bristol Myers Squibb.' />
                        <Button variant='filled'>Iniciar Sesión</Button>
                        <Button variant='outline' color='darkGrey'>
                            Recuperar contraseña
                        </Button> */}
                        </form>
                    </Tabs.Panel>
                </Tabs>
            ) : (
                <form className={styles.form}>
                    <InputText
                        handleInputChange={handleInputChange}
                        initialValue=''
                        label='Usuario'
                        name='username'
                        placeholder='Escriba aquí su nombre de usuario'
                    />
                    <InputPassword
                        color='purple'
                        handleInputChange={handleInputChange}
                        initialValue=''
                        label='Contraseña'
                        name='password'
                        placeholder='Escriba aquí su contraseña'
                    />
                    <InputCheckbox
                        handleCheckboxChange={handleCheckboxChange}
                        initialValue={false}
                        label={<CheckboxLabel />}
                        name='terms'
                    />
                    <Button variant='filled' onClick={handleSubmit}>
                        {isFetching ? (
                            <Loader size='xs' color='rgba(255, 255, 255, 1)' />
                        ) : (
                            'Iniciar Sesión'
                        )}
                    </Button>
                    <Button variant='outline' color='darkGrey'>
                        Recuperar contraseña
                    </Button>
                </form>
            )}
        </div>
    )
}
export default FormLogin
