const inputTextTheme = (mantineTheme, theme) => {
    const themes = {
        default: {
            label: {
                color: mantineTheme.colors.darkGrey[0],
                fontSize: mantineTheme.fontSizes.lg,
            },
            input: {
                marginTop: mantineTheme.spacing.xs,
                fontSize: mantineTheme.fontSizes.xl,
            },
            innerInput: {
                paddingTop: mantineTheme.spacing['2xs'],
                '&::placeholder': {
                    fontSize: mantineTheme.fontSizes.md,
                },
            },
            error: {
                fontSize: mantineTheme.fontSizes.sm,
                fontWeight: 'bold',
                marginTop: mantineTheme.spacing.xs,
            },
        },
    }
    return !theme ? themes.default : themes[theme]
}

export default inputTextTheme
