import { useSelector } from 'react-redux'

const useFooterMain = () => {
    const navigation = useSelector((state) => state.navigation)
    return {
        projectAuthNumber: process.env.REACT_APP_AUTH_NUMBER,
        instanceCountryCode: process.env.REACT_APP_COUNTRY_CODE,
        legalLinks: !navigation ? null : navigation.legal,
        shortcutsLinks: !navigation ? null : navigation.shortcuts,
    }
}
export default useFooterMain

/*
const legalLinks = [
        {
            label: 'Sobre BMS',
            target: '_blank',
            url: '/sobre-bms',
        },
        {
            label: 'Aviso de privacidad',
            target: '_self',
            url: '/aviso-de-privacidad',
        },
        {
            label: 'Aviso legal y Términos y condiciones',
            target: '_self',
            url: '/aviso-legal',
        },
    ]
*/
