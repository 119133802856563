//# Settings Actions
import { SETTINGS_SET_COLOR_SCHEME, SETTINGS_SET_FONT_SIZE } from '../constants/settings'

//* Settings set color scheme
export const settingsSetColorScheme = (colorScheme) => {
    return async function (dispatch) {
        dispatch({
            type: SETTINGS_SET_COLOR_SCHEME,
            payload: colorScheme,
        })
    }
}

//* Settings set font size
export const settingsSetGlobalFontSize = (globalFontSize) => {
    return async function (dispatch) {
        dispatch({
            type: SETTINGS_SET_FONT_SIZE,
            payload: globalFontSize,
        })
    }
}
