import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Select } from '@mantine/core'
import { inputSelectTheme } from 'assets/themes/inputSelectTheme'

const InputSelect = ({
    error,
    fieldset,
    handleInputChange,
    label,
    name,
    options,
    placeholder,
}) => {
    const [searchValue, onSearchChange] = useState('')
    useEffect(() => {
        if (options && searchValue !== '') {
            const selectedOption = options.find((option) => {
                if (option.label === searchValue) {
                    return option
                }
                return false
            })
            handleInputChange(name, selectedOption.value, fieldset)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue])

    //TODO: Enable custom filter
    /*
    const normalizedString = (string) =>
                string
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLowerCase()
    const customFilter = (value, searchterm) => {
        if (typeof searchterm !== 'string') {
            // Si searchterm no es una cadena, simplemente lo convertimos en una cadena vacía
            searchterm = ''
        }

        const normalizedSearchTerm = searchterm
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()

        const normalizedValue = value
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
        return normalizedValue.includes(normalizedSearchTerm)
    }
filter={customFilter}
    */

    return (
        <Select
            data={options ? options.map((item) => item.label) : []}
            error={error}
            label={label}
            maxDropdownHeight={'25vh'}
            nothingFound='No options'
            onSearchChange={onSearchChange}
            placeholder={placeholder}
            searchValue={searchValue}
            searchable={false}
            styles={inputSelectTheme}
            withAsterisk
        />
    )
}

export default InputSelect

InputSelect.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    label: PropTypes.string,
    name: PropTypes.string,
    handleInputChange: PropTypes.func,
}
InputSelect.defaultProps = {
    data: [],
    label: null,
    name: null,
    handleInputChange: () => {},
}
