const rootStyles = {
    height: 'auto',
    width: '100%',
    fontWeight: 'normal',
    span: {
        overflow: 'visible',
    },
}
export const inputSelectTheme = (mantineTheme) => {
    return {
        root: { ...rootStyles },
        label: {
            color: mantineTheme.colors.darkGrey[0],
            fontSize: mantineTheme.fontSizes.lg,
        },
        leftIcon: {
            marginRight: '0.5rem',
        },
        rightIcon: {
            marginLeft: '0.5rem',
        },
        dropdown: {
            zIndex: '99999',
        },
        input: {
            borderRadius: mantineTheme.radius.sm,
            fontSize: mantineTheme.fontSizes.md,
            marginTop: mantineTheme.spacing.xs,
            '&::placeholder': { fontSize: mantineTheme.fontSizes.md },
            '&[data-invalid]': {
                backgroundColor: mantineTheme.colors.danger[9],
                border: `solid 1px ${mantineTheme.colors.danger[4]}`,
                color: mantineTheme.colors.danger[2],
                '&::placeholder': {
                    color: mantineTheme.colors.danger[4],
                },
            },
        },
        error: {
            color: mantineTheme.colors.danger[4],
            fontSize: mantineTheme.fontSizes.sm,
            fontWeight: 'bold',
            marginTop: mantineTheme.spacing.xs,
        },
    }
}
