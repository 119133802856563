import { posts } from '../../../../data/appData'
import { useParams } from 'react-router-dom'
import { getBreadcrumsData, getPageTheme, getPageData } from 'utils/utils'
const useArticlesAndBrochures = () => {
    const params = useParams()
    const breadcrumbsData = getBreadcrumsData(params, posts)
    const pageTheme = getPageTheme(params)
    const pageData = getPageData(posts, params)
    const articles = posts
        .filter((article) => pageData.content.articles.includes(article.url))
        .map((article) => {
            return {
                metaDescription: article.metaDescription,
                title: article.title,
                summary: article.summary,
                imageSource: article.media.coverImage.src,
                linkLabel: `Más informacion >`,
                linkUrl: `${article.url}`,
            }
        })
    return { breadcrumbsData, pageTheme, pageData, articles }
}
export default useArticlesAndBrochures
