//# Pages reducer

import { GET_PAGE } from 'store/constants/pages'

const initialState = {
    index: [],
    pages: [],
}

export const pagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PAGE:
            if (!state.index.includes(action.payload.data.slug)) {
                return {
                    ...state,
                    index: [...state.index, action.payload.data.slug],
                    pages: [...state.pages, action.payload],
                }
            }
            return state

        default:
            return state
    }
}
