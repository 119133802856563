import { posts } from '../../../../data/appData'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getBreadcrumsData, getPageData } from 'utils/utils'
const useMyMonitor = () => {
    const instance = process.env.REACT_APP_INSTANCE
    const params = useParams()
    const { url1 } = params
    const { pages } = useSelector((state) => state.pages)
    const breadcrumbsData = getBreadcrumsData(params, posts)
    const pageData = getPageData(pages, url1)

    /* const params = useParams()
    const breadcrumbsData = getBreadcrumsData(params, posts)
    const pageTheme = getPageTheme(params)
    const pageData = getPageData(posts, params)
    return { breadcrumbsData, pageTheme, pageData } */

    return {
        instance,
        breadcrumbsData,
        pageData: pageData ? pageData.data : null,
    }
}
export default useMyMonitor
