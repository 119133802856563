import { Button, Grid, Loader } from '@mantine/core'
import InputSelect from 'components/UI/Inputs/InputSelect/InputSelect'
import InputText from 'components/UI/Inputs/InputText/InputText'
import useFormInformedConsent from './hooks/useFormInformedConsent'
import styles from './styles.module.scss'

const FormInformedConsent = ({
    referencedProducts,
    referencedTherapeuticIndications,
    referencedProfessionalSpecialties,
}) => {
    const {
        patientData,
        professionalData,
        errors,
        isFetching,
        handleInputChange,
        handleSubmit,
    } = useFormInformedConsent()
    return (
        <div className={styles.container}>
            <form id='autorizationRequestForm' className={styles.form}>
                <Grid>
                    <Grid.Col
                        md={12}
                        lg={12}
                        sx={(theme) => ({
                            padding: theme.spacing.sm,
                        })}
                    >
                        <div className={styles.title}>
                            Información de paciente
                        </div>
                    </Grid.Col>
                    <Grid.Col
                        md={6}
                        lg={6}
                        sx={(theme) => ({
                            padding: theme.spacing.sm,
                        })}
                    >
                        <InputText
                            error={errors.patient.names}
                            fieldset='patient'
                            handleInputChange={handleInputChange}
                            initialValue={patientData.names}
                            label='Nombres'
                            name='names'
                            placeholder='Escriba aquí los nombres del paciente'
                        />
                    </Grid.Col>
                    <Grid.Col
                        md={6}
                        lg={6}
                        sx={(theme) => ({
                            padding: theme.spacing.sm,
                        })}
                    >
                        <InputText
                            error={errors.patient.surnames}
                            fieldset='patient'
                            handleInputChange={handleInputChange}
                            initialValue={patientData.surnames}
                            label='Apellidos'
                            name='surnames'
                            placeholder='Escriba aquí los apellidos del paciente'
                        />
                    </Grid.Col>
                    <Grid.Col
                        md={6}
                        lg={6}
                        sx={(theme) => ({
                            padding: theme.spacing.sm,
                        })}
                    >
                        <InputText
                            error={errors.patient.email}
                            fieldset='patient'
                            handleInputChange={handleInputChange}
                            initialValue={patientData.email}
                            label='Correo Electrónico'
                            name='email'
                            placeholder='Escriba aquí un correo electrónico de contacto'
                        />
                    </Grid.Col>
                    <Grid.Col
                        md={6}
                        lg={6}
                        sx={(theme) => ({
                            padding: theme.spacing.sm,
                        })}
                    >
                        <InputText
                            error={errors.patient.mobile}
                            fieldset='patient'
                            handleInputChange={handleInputChange}
                            initialValue={patientData.mobile}
                            label='Teléfono Móvil (incluir código de área)'
                            minLength={7}
                            name='mobile'
                            placeholder='Escriba aquí un numero móvil de contacto'
                        />
                    </Grid.Col>
                    <Grid.Col
                        md={6}
                        lg={6}
                        sx={(theme) => ({
                            padding: theme.spacing.sm,
                        })}
                    >
                        <InputSelect
                            error={errors.patient.product}
                            fieldset='patient'
                            handleInputChange={handleInputChange}
                            initialValue={patientData.product}
                            label='Producto Asignado al Paciente'
                            name='product'
                            options={referencedProducts.map((product) => {
                                return {
                                    label: product.name,
                                    value: product._id,
                                }
                            })}
                            placeholder='Seleccione el medicamento del paciente'
                        />
                    </Grid.Col>
                    <Grid.Col
                        md={6}
                        lg={6}
                        sx={(theme) => ({
                            padding: theme.spacing.sm,
                        })}
                    >
                        <InputSelect
                            error={errors.patient.therapeuticIndication}
                            fieldset='patient'
                            handleInputChange={handleInputChange}
                            initialValue={patientData.terapeuthicIndications}
                            label='Indicación Terapéutica del Paciente'
                            name='therapeuticIndication'
                            options={referencedTherapeuticIndications.map(
                                (therapeuticIndication) => {
                                    return {
                                        label: therapeuticIndication.name,
                                        value: therapeuticIndication._id,
                                    }
                                }
                            )}
                            placeholder='Seleccione la indicación terapéutica del paciente'
                        />
                    </Grid.Col>
                    <Grid.Col
                        md={12}
                        lg={12}
                        sx={(theme) => ({
                            padding: theme.spacing.sm,
                        })}
                    >
                        <div className={styles.title}>
                            Información del Medico Tratante
                        </div>
                    </Grid.Col>
                    <Grid.Col
                        md={6}
                        lg={6}
                        sx={(theme) => ({
                            padding: theme.spacing.sm,
                        })}
                    >
                        <InputText
                            error={errors.professional.names}
                            fieldset='professional'
                            handleInputChange={handleInputChange}
                            initialValue={professionalData.names}
                            label='Nombres'
                            name='names'
                            placeholder='Escriba aquí los nombres del médico tratante'
                        />
                    </Grid.Col>
                    <Grid.Col
                        md={6}
                        lg={6}
                        sx={(theme) => ({
                            padding: theme.spacing.sm,
                        })}
                    >
                        <InputText
                            error={errors.professional.surnames}
                            fieldset='professional'
                            handleInputChange={handleInputChange}
                            initialValue={professionalData.surnames}
                            label='Apellidos'
                            name='surnames'
                            placeholder='Escriba aquí los apellidos del profesional'
                        />
                    </Grid.Col>
                    <Grid.Col
                        md={6}
                        lg={6}
                        sx={(theme) => ({
                            padding: theme.spacing.sm,
                        })}
                    >
                        <InputSelect
                            error={errors.professional.specialty}
                            fieldset='professional'
                            handleInputChange={handleInputChange}
                            initialValue={professionalData.specialty}
                            label='Especialidad'
                            name='specialty'
                            options={referencedProfessionalSpecialties.map(
                                (specialty) => {
                                    return {
                                        label: specialty.name,
                                        value: specialty._id,
                                    }
                                }
                            )}
                            placeholder='Seleccione su especialidad'
                        />
                    </Grid.Col>
                    <Grid.Col
                        md={6}
                        lg={6}
                        sx={(theme) => ({
                            padding: theme.spacing.sm,
                        })}
                    >
                        <InputText
                            error={errors.professional.professionalRegistration}
                            fieldset='professional'
                            handleInputChange={handleInputChange}
                            initialValue={
                                professionalData.professionalRegistration
                            }
                            label='Matricula'
                            minLength={5}
                            name='professionalRegistration'
                            placeholder='Escriba aquí su numero de matricula'
                        />
                    </Grid.Col>
                    <Grid.Col
                        md={6}
                        lg={6}
                        sx={(theme) => ({
                            padding: theme.spacing.sm,
                        })}
                    >
                        <InputText
                            error={errors.professional.email}
                            fieldset='professional'
                            handleInputChange={handleInputChange}
                            initialValue={professionalData.email}
                            label='Correo Electrónico'
                            name='email'
                            placeholder='Escriba aquí un correo electrónico del médico tratante'
                        />
                    </Grid.Col>
                    <Grid.Col
                        md={12}
                        lg={12}
                        sx={(theme) => ({
                            padding: theme.spacing.sm,
                        })}
                    >
                        <Button
                            variant='filled'
                            onClick={handleSubmit}
                            sx={{ width: '100%' }}
                            radius='sm'
                        >
                            {isFetching ? (
                                <Loader
                                    size='xs'
                                    color='rgba(255, 255, 255, 1)'
                                />
                            ) : (
                                'Registrar Usuario'
                            )}
                        </Button>
                    </Grid.Col>
                </Grid>
            </form>
        </div>
    )
}

export default FormInformedConsent
