import React from 'react'
import { useSelector } from 'react-redux'
import FormLogin from 'components/UI/Forms/FormLogin/FormLogin'
import Logo from 'components/UI/Logo/Logo'
import styles from './styles.module.scss'

const Login = ({ pageData, projectAuthNumber, instanceCountryCode }) => {
    const { colorScheme } = useSelector((state) => state.settings)
    return !pageData ? (
        <div>Loading</div>
    ) : (
        <div className={styles.layout}>
            <div className={styles.loginCard}>
                <div className={styles.logo}>
                    <Logo logo='bmsLogo' theme={colorScheme} />
                </div>
                <div className={styles.title}>{pageData.data.title}</div>
                <div className={styles.subtitle}>{pageData.data.subtitle}</div>
                <FormLogin />
                <div className={styles.authNumber}>
                    {instanceCountryCode === 'mx'
                        ? `Número de aviso SSA: ${projectAuthNumber}`
                        : `Autorización No. SSA: ${projectAuthNumber}`}
                </div>
            </div>
            <div className={styles.slide}>&nbsp;</div>
        </div>
    )
}

export default Login
