import { posts } from '../../../../data/appData'
import { useParams } from 'react-router-dom'
import { getBreadcrumsData, getPageTheme, getPageData } from 'utils/utils'
const useTermsAndConditions = () => {
    const params = useParams()
    const breadcrumbsData = getBreadcrumsData(params, posts)
    const pageTheme = getPageTheme(params)
    const pageData = getPageData(posts, params)
    return { breadcrumbsData, pageTheme, pageData }
}
export default useTermsAndConditions
