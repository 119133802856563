//# Pages services
export const getPageService = async (slug) => {
    const apiUrl = process.env.REACT_APP_API_URL
    const instance = process.env.REACT_APP_INSTANCE
    if (slug === 'mantenimiento') {
        localStorage.clear()
    }
    if (['', 'undefined'].includes(localStorage.token)) {
        localStorage.clear()
    }
    //const pagesArray = ['', 'iniciar-sesion', 'consentimiento-informado']
    try {
        const token =
            localStorage.token !== undefined
                ? JSON.parse(localStorage.token)
                : ''
        const route =
            localStorage.token !== undefined
                ? `pages/${slug}`
                : `pages/${slug}?i=${instance}`
        const endPoint = `${apiUrl}${route}`

        const headers = new Headers()
        headers.append('Content-Type', 'application/json, text/plain')
        if (localStorage.token !== undefined) {
            headers.append('Authorization', `Bearer ${token}`)
        }

        const response = await fetch(`${endPoint}`, {
            headers: headers,
            method: 'GET',
        })

        const result = await response.json()

        if (result.status) {
            return result.data
        }
    } catch (error) {
        throw new Error(error)
    }
}
