//# Navigation reducer

import { GET_NAVIGATION } from 'store/constants/navigation'

const initialState = null

export const navigationReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_NAVIGATION:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state
    }
}
