import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useMantineTheme } from '@mantine/core'
import { TextInput } from '@mantine/core'
import inputTextTheme from 'assets/themes/inputTextTheme'

const InputText = ({
    autocomplete,
    error,
    fieldset,
    handleInputChange,
    icon,
    initialValue,
    label,
    minLength,
    name,
    placeholder,
    required,
    theme,
    variant,
}) => {
    const [value, setValue] = useState(initialValue)
    const previousValue = useRef(initialValue)
    const mantineTheme = useMantineTheme()
    const inputTheme = inputTextTheme(mantineTheme, theme)

    useEffect(() => {
        if (previousValue.current !== value) {
            handleInputChange(name, value, fieldset, minLength)
            previousValue.current = value
        }
    }, [value, handleInputChange, name, fieldset, minLength])

    return (
        <TextInput
            error={error || false}
            icon={icon}
            label={label || false}
            name={name}
            onChange={(event) => setValue(event.currentTarget.value)}
            placeholder={placeholder}
            styles={inputTheme}
            value={value}
            variant={variant}
            withAsterisk={required || false}
            autoComplete={autocomplete || 'off'}
        />
    )
}

export default InputText

InputText.propTypes = {
    icon: PropTypes.node,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    name: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    theme: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    handleInputChange: PropTypes.func,
}

InputText.defaultProps = {
    icon: null,
    label: false,
    name: 'Input Name',
    placeholder: 'Input Placeholder',
    required: false,
    theme: false,
    handleInputChange: () => {},
}
