import React from 'react'
import { BackgroundImage, Container } from '@mantine/core'

import HeaderMain from 'components/UI/Headers/HeaderMain/HeaderMain'
import NavigationMain from 'components/UI/NavigationMain/NavigationMain'
import FooterMain from 'components/UI/FooterMain/FooterMain'
import HeaderPage from 'components/UI/Headers/HeaderPage/HeaderPage'
import BreadcrumbsMain from 'components/UI/Breadcrums/BreadcrumsMain/BreadcrumsMain'
import CardsContainer from 'components/UI/Containers/CardsContainer/CardsContainer'
import SubjectBlock from 'components/UI/Blocks/SubjectBlock/SubjectBlock'
import useWhatDoesTheProgramOffers from './hooks/useWhatDoesTheProgramOffers'

import styles from './styles.module.scss'
const WhatDoesTheProgramOffers = () => {
    const { breadcrumbsData, pageData, articles } =
        useWhatDoesTheProgramOffers()
    return !pageData ? (
        <div>Loading</div>
    ) : (
        <div className={styles.container}>
            <div className={styles.layout}>
                <HeaderMain />
                <NavigationMain />
                <main>
                    <HeaderPage
                        title={pageData.title}
                        summary={pageData.summary}
                        theme='themeAmber'
                    />
                    <BreadcrumbsMain items={breadcrumbsData} />
                    <Container size='1280px' px={0} py='2x'>
                        <BackgroundImage
                            src={pageData.media.coverImage.src}
                            sx={{
                                aspectRatio: '5/1',
                            }}
                        />
                    </Container>
                    <CardsContainer
                        aspectRatio='4/5'
                        cols={2}
                        component={<SubjectBlock orientation='horizontal' />}
                        items={articles}
                    />
                </main>
                <FooterMain />
            </div>
        </div>
    )
}

export default WhatDoesTheProgramOffers
