//# Navigation services
import arDataNavigation from './../data/argentina/dataNavigation.json'
import chDataNavigation from './../data/chile/dataNavigation.json'
import coDataNavigation from './../data/colombia/dataNavigation.json'
import mxDataNavigation from './../data/mexico/dataNavigation.json'

//import dataNavigation from './../data/argentina/dataNavigation.json'
export const getNavigationService = async (payload) => {
    const instance = process.env.REACT_APP_INSTANCE
    const apiUrl = process.env.REACT_APP_API_URL
    const instancesNavigationData = {
        ar: arDataNavigation,
        ch: chDataNavigation,
        co: coDataNavigation,
        mx: mxDataNavigation,
    }
    //const { url, token, endPoint } = payload
    try {
        /*
        const headers = {
            Accept: 'application/json, text/plain',
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: token,
        }

        const payload = {
            url,
            token,
        }
        */

        let response = await fetch(`${apiUrl}navigation/${instance}`)
        response = await response.json()

        //const response = instancesNavigationData[instance]

        if (response) {
            return response.data
        }
    } catch (error) {
        throw new Error(error)
    }
}
