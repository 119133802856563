import React from 'react'
import { Loader } from '@mantine/core'
import styles from './styles.module.scss'

const PageLoader = () => {
    return (
        <div className={styles.container}>
            <Loader type='dots' size='64' />
            <div className={styles.label}>Cargando</div>
        </div>
    )
}
export default PageLoader
