import { mantineTheme } from './mainTheme'

const modalTheme = (mantineTheme) => {
    return {
        default: {
            root: { backgroundColor: 'red' },
            content: {},
            header: {
                backgroundColor: mantineTheme.colors.lightGrey[0],
                borderBottom: `solid 1px ${mantineTheme.colors.purple[0]}`,
            },
            title: {
                color: mantineTheme.colors.darkGrey[0],
                fontSize: mantineTheme.fontSizes['2x'],
            },
            body: { padding: 0 },
        },
    }
}

export default modalTheme
