import React from 'react'
import { BackgroundImage, Container, Grid, Text, Title } from '@mantine/core'

import HeaderMain from 'components/UI/Headers/HeaderMain/HeaderMain'
import NavigationMain from 'components/UI/NavigationMain/NavigationMain'
import FooterMain from 'components/UI/FooterMain/FooterMain'
import HeaderPage from 'components/UI/Headers/HeaderPage/HeaderPage'
import BreadcrumbsMain from 'components/UI/Breadcrums/BreadcrumsMain/BreadcrumsMain'
import CardsContainer from 'components/UI/Containers/CardsContainer/CardsContainer'
import StoryCard from 'components/UI/Cards/StoryCard/StoryCard'
import PageLoader from 'components/UI/PageLoader/PageLoader'

import useScreen from 'hooks/useScreen'
//import usePost from './hooks/usePost'
import styles from './styles.module.scss'

const Post = () => {
    const { pageData, referencedPosts, referencedPages } = useScreen()
    if (!pageData) {
        return <PageLoader />
    } else {
        const { data } = pageData
        const fileExtension = data.links[0].url
        return (
            <div className={styles.layout}>
                <HeaderMain />
                <NavigationMain />
                <main>
                    <HeaderPage
                        title={data.title}
                        summary={data.summary}
                        theme='themeAmber'
                    />
                    <BreadcrumbsMain />
                    <Container size='1280px' px={0}>
                        <Grid>
                            <Grid.Col span={9}>
                                {data.links[0].url
                                    .split('/')
                                    .pop()
                                    .split('.')
                                    .pop() === 'pdf' ? (
                                    <>
                                        <iframe
                                            title='PDF Viewer'
                                            src={data.links[0].url}
                                            width='100%'
                                            height='720px'
                                            frameborder='0'
                                            style={{
                                                overflow: 'hidden',
                                                borderRadius: '.25rem',
                                            }}
                                        />
                                        {data.summary && (
                                            <Text
                                                fz='lg'
                                                mt='2x'
                                                c='darkGrey.0'
                                            >
                                                {data.summary
                                                    .split('\n')
                                                    .map((paragraph, index) => (
                                                        <React.Fragment
                                                            key={index}
                                                        >
                                                            {paragraph}
                                                            <br />
                                                            <br />
                                                        </React.Fragment>
                                                    ))}
                                            </Text>
                                        )}
                                    </>
                                ) : (
                                    <video width='100%' height='100%' controls>
                                        <source
                                            src={data.links[0].url}
                                            type='video/mp4'
                                        />
                                        Your browser does not support the video
                                        tag.
                                    </video>
                                )}
                            </Grid.Col>
                            <Grid.Col span={3}>
                                <Title
                                    c='darkGrey.0'
                                    order={3}
                                    sx={(theme) => ({
                                        borderBottomColor:
                                            theme.colors.purple[1],
                                        borderBottomWidth: '2px',
                                        borderBottomStyle: 'solid',
                                    })}
                                >
                                    {!referencedPosts
                                        ? 'No existen publicaciones relacionadas con este articulo'
                                        : 'Publicaciones relacionadas'}
                                </Title>
                                {referencedPosts && (
                                    <CardsContainer
                                        aspectRatio='16/9'
                                        cols={1}
                                        component={<StoryCard />}
                                        items={referencedPosts}
                                    />
                                )}
                            </Grid.Col>
                        </Grid>
                    </Container>
                </main>
                <FooterMain />
            </div>
        )
    }
}

export default Post
