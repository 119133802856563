import React from 'react'
import { Container, List, Table } from '@mantine/core'
import HeaderMain from 'components/UI/Headers/HeaderMain/HeaderMain'
import FooterMain from 'components/UI/FooterMain/FooterMain'
import HeaderPage from 'components/UI/Headers/HeaderPage/HeaderPage'
import BreadcrumbsMain from 'components/UI/Breadcrums/BreadcrumsMain/BreadcrumsMain'
import useTermsAndConditions from './hooks/useTermsAndConditions'
import styles from './styles.module.scss'
const TermsAndConditions = () => {
    const { breadcrumbsData, pageTheme, pageData } = useTermsAndConditions()
    const { title, summary } = pageData
    return (
        <div className={styles.container}>
            <div className={styles.layout}>
                <HeaderMain />
                <main>
                    <HeaderPage title={title} summary={summary} theme={pageTheme} />
                    <BreadcrumbsMain items={breadcrumbsData} />
                    <Container size='1280px' px={0} pb='4x'>
                        <p>
                            1. ¿Quién es responsable del tratamiento de sus datos
                            personales?
                        </p>
                        <p>
                            1.1 BRISTOL-MYERS SQUIBB DE MEXICO S. DE R.L. DE C.V. (“BMS”),
                            con domicilio en Insurgentes Sur 1602 Piso 5, Colonia Crédito
                            Constructor, Benito Juárez, Ciudad de México, C.P. 03940,
                            México, es el responsable de sus datos personales como titular
                            del Programa de Apoyo al Paciente “Vamos Contigo”, quién
                            contrató a PHARMAFORCE, S.A. DE C.V. (“PHARMAFORCE”) para
                            ejecutar el Programa, adquiriendo el carácter de encargado del
                            tratamiento.
                        </p>
                        <p>
                            1.2. Para cualquier información respecto del presente Aviso de
                            Privacidad o en relación con el ejercicio de cualesquiera de
                            sus derechos derivados de los datos personales referidos,
                            incluyendo sin limitación sus derechos de acceso,
                            rectificación, cancelación y oposición, así como para limitar
                            su tratamiento o revocar el consentimiento que nos haya sido
                            otorgado, por favor contacte a nuestro Departamento de Datos
                            Personales en nuestro domicilio antes mencionado o al correo
                            electrónico: datospersonales.psp@bms.com
                        </p>
                        <p>
                            2. Definiciones. Para efectos de este Aviso de Privacidad, los
                            términos siguientes tendrán el significado que se detalla a
                            continuación.
                        </p>
                        <p>
                            2.1 Datos personales.- Cualquier información concerniente a
                            usted.
                        </p>
                        <p>
                            2.2 Datos personales sensibles.- Aquellos datos personales que
                            afecten a la esfera más íntima de su TITULAR, o cuya
                            utilización indebida pueda dar origen a discriminación o
                            conlleve un riesgo grave para este.
                        </p>
                        <p>
                            2.3 Derechos ARCO.- Derechos de Acceso, Rectificación,
                            Cancelación y Oposición que tiene todo Titular.
                        </p>
                        <p>
                            2.4 Encargado.- Persona física o jurídica que sola o
                            conjuntamente con otras trate datos personales por cuenta del
                            responsable (incluyendo a PHARMAFORCE).
                        </p>
                        <p>
                            2.5 Finalidades Primarias.- Aquellas finalidades para las
                            cuales se solicitan principalmente los datos personales y por
                            lo que se da origen a la relación entre BMS y usted.
                        </p>
                        <p>
                            2.6 Finalidades Secundarias.- Aquellas finalidades que no son
                            imprescindibles para la relación entre BMS y usted.
                        </p>
                        <p>
                            2.7 Programa.- El programa de apoyo al paciente “Vamos
                            Contigo” cuya finalidad es asistirle en trámites
                            administrativos relativos al acceso al medicamento prescrito
                            por su médico, otorgarle dosis de inicio y/o continuidad y
                            pruebas de seguimiento, y brindarle educación, asistencia y
                            acompañamiento en el uso adecuado del medicamento.
                        </p>
                        <p> 2.8 Responsable.- Significa BMS. </p>
                        <p>2.9 Titular.- Usted, como paciente inscrito al Programa.</p>
                        <p>
                            2.10 Transferencia.- Toda comunicación de datos que se
                            realizará a persona distinta del responsable o encargado del
                            tratamiento.
                        </p>
                        <p>
                            2.11 Tratamiento.- La obtención, uso (que incluye el acceso,
                            manejo, aprovechamiento, transferencia o disposición de datos
                            personales), divulgación o almacenamiento de datos personales
                            por cualquier medio.
                        </p>
                        <p>3. ¿Qué datos personales tratamos? </p>
                        <p>
                            3.1 A efecto de cumplir con las finalidades señaladas en el
                            presente Aviso, BMS tratará datos de identificación, contacto,
                            y ubicación.
                        </p>
                        <p>
                            3.2 Asimismo, le informamos que se tratarán datos personales
                            de salud, los cuales son necesarios para la prestación de los
                            beneficios del Programa. Los datos de salud son datos
                            personales sensibles. Todos los datos personales los obtenemos
                            directamente de usted.
                        </p>
                        <p>4. ¿Para qué usamos sus datos personales?</p>
                        <p>
                            4.1 BMS tratará sus datos personales para las siguientes
                            finalidades primarias:
                        </p>
                        <p>
                            4.1.1 Al tener contacto con Usted por los medios que el
                            Responsable ha definido para ejecutar el Programa, sus datos
                            personales serán tratados para dar atención a las solicitudes
                            o requerimientos presentados, así como registrar dicha
                            petición y darle seguimiento.
                        </p>
                        <p>
                            4.1.2 Para los casos en los que tengamos contacto con Usted
                            vía telefónica a través de los medios de comunicación puestos
                            a disposición por el Responsable, le informamos que la llamada
                            será grabada para realizar revisiones de calidad, así como
                            para contar evidencias del cumplimiento de las leyes que son
                            aplicables al Responsable.
                        </p>
                        <p>
                            4.1.3 Identificarlo, y en su caso, contactarlo para invitarlo
                            para formar parte del Programa y gozar de sus beneficios.
                        </p>
                        <p>
                            4.1.4 Registrarlo como beneficiario del Programa y gozar de
                            sus beneficios.
                        </p>
                        <p>
                            4.1.5 En su caso, realizar la gestión de trámites referentes
                            al registro de su estado de salud para que le sean autorizados
                            y proporcionados los medicamentos que le sean prescritos por
                            su médico tratante.
                        </p>
                        <p>
                            4.1.6 En su caso, entregarle o hacerle llegar Kits de
                            bienvenida al Programa o medicamentos originales de obsequio
                            (dosis de inicio o continuidad).
                        </p>
                        <p>
                            4.1.7 Contactarlo vía telefónica o por correo electrónico, o
                            en su caso, visitarlo en su domicilio, para dar seguimiento a
                            la adherencia a sus tratamientos médicos, monitoreo de su
                            estado de salud, informarle la logística para la ejecución de
                            estudios clínicos o terapias programadas.
                        </p>
                        <p>
                            4.1.8 Registro de las actividades de monitoreo y seguimiento
                            realizadas con motivo de la ejecución del Programa.
                        </p>
                        <p>
                            4.1.9 En su caso, ejecutar las actividades de logística que
                            permitan la prestación de estudios clínicos, terapias o
                            asistencia a centros de infusión.
                        </p>
                        <p>
                            4.1.10 En su caso, brindarle asesoría psicológica o
                            nutricional, vía telefónica.
                        </p>
                        <p>
                            4.1.11 Invitarlo a eventos que forman parte de los beneficios
                            del Programa y contar con evidencia de su asistencia.
                        </p>
                        <p>
                            4.1.12 En su caso, apoyarlo para realizar trámites ante su
                            aseguradora.
                        </p>
                        <p>
                            4.1.13 Asistencia telefónica para atender cualquier duda
                            referente al Programa.
                        </p>
                        <p>
                            4.1.14 Almacenamiento y resguardo de los documentos generados
                            con motivo de la ejecución de las actividades derivadas del
                            Programa.
                        </p>
                        <p>
                            4.1.15 Generar reportes de operación, estadísticos y/o
                            históricos en forma disociada.
                        </p>
                        <p>
                            4.2 Le informamos que trataremos sus datos de identificación y
                            contacto para las siguientes finalidades secundarias:
                        </p>
                        <p>
                            4.2.1 Efectuar encuestas de satisfacción y calidad de vida. En
                            caso de que usted no desee que usemos sus datos para estas
                            finalidades, por favor envíe una solicitud al siguiente correo
                            electrónico: datospersonales.psp@bms.com{' '}
                        </p>
                        <p>5. ¿Con quién compartimos sus datos personales?</p>
                        <p>
                            5.1 Para dar cumplimiento a las finalidades indicadas
                            anteriormente y, en su caso, las que fueren exigidas
                            legalmente o por autoridades competentes, a través de
                            solicitud fundada y motivada, se compartirán sus datos
                            personales con los siguientes terceros:
                        </p>
                        <p>
                            <Table
                                striped
                                highlightOnHover
                                withBorder
                                withColumnBorders
                                verticalSpacing='md'
                                fontSize='md'
                            >
                                <thead>
                                    <tr>
                                        <th>Tercero receptor</th>
                                        <th>Finalidad(es)</th>
                                        <th>Consentimiento</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Pharmaforce</td>
                                        <td>Gestionar el Programa</td>
                                        <td>
                                            No necesario debido a que Pharmaforce actúa
                                            como nuestro encargado y sólo usará sus datos
                                            conforme a las instrucciones de BMS y sujeto a
                                            este aviso de privacidad.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Laboratorios filiales y/o centros de infusión.
                                        </td>
                                        <td>
                                            Agendar citas para la realización de estudios
                                            clínicos o terapias.
                                        </td>
                                        <td>
                                            No necesario debido a que los laboratorios
                                            utilizarán sus datos para cumplir con las
                                            finalidades del programa.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Farmacias Especializadas.</td>
                                        <td>Entrega de medicamentos.</td>
                                        <td>
                                            No necesario debido a que las farmacias
                                            utilizarán sus datos para cumplir con las
                                            finalidades del programa.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Empresas prestadoras del servicio de
                                            transporte.
                                        </td>
                                        <td>Facilitar traslados.</td>
                                        <td>
                                            No necesario debido a que los transportistas
                                            utilizarán sus datos para cumplir con las
                                            finalidades del programa.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Autoridades competentes.</td>
                                        <td>
                                            Dar cumplimiento a requerimientos de
                                            autoridades competentes, previa solicitud
                                            debidamente fundada y motivada.
                                        </td>
                                        <td>No necesario.</td>
                                    </tr>
                                    <tr>
                                        <td>Empresas subsidiarias o filiales.</td>
                                        <td>Tratamiento para finalidades primarias.</td>
                                        <td>No necesario.</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </p>
                        <p>
                            5.2 Se informa que ninguna de las transferencias antes
                            indicadas requiere de su consentimiento, toda vez que las
                            finalidades de las mismas es dar cumplimiento a las
                            finalidades del Programa u obligaciones legales de BMS.
                        </p>
                        <p>
                            6. Ejercicio de Derecho de Acceso, Rectificación, Cancelación,
                            Oposición (ARCO).
                        </p>
                        <p>
                            6.1 Usted tiene derecho de acceder, rectificar y cancelar sus
                            datos personales, oponerse al tratamiento de los mismos,
                            limitar su uso o divulgación, o revocar el consentimiento que
                            nos ha otorgado para el tratamiento de sus datos (estos
                            derechos se conocen como derechos “ARCO”), enviando una
                            solicitud al siguiente correo electrónico:
                            datospersonales.psp@bms.com
                        </p>
                        <p>
                            6.2 Su solicitud deberá contener al menos la siguiente
                            información: (i) nombre completo y correo electrónico o
                            domicilio para comunicarle la respuesta a su solicitud; (ii)
                            los documentos que acrediten su identidad o, en su caso, la
                            representación legal; (iii) la descripción clara y precisa de
                            los datos personales respecto de los que busca ejercer alguno
                            de los derechos antes mencionados; y (iv) cualquier otro
                            elemento o documento que facilite la localización de los datos
                            personales. De no cumplir con el punto señalado en el numeral
                            (ii), la solicitud se tendrá como no presentada.
                        </p>
                        <p>
                            6.3 Su petición será atendida dentro del plazo establecido por
                            la ley y le informaremos sobre la procedencia de la misma a
                            través del correo electrónico o domicilio que nos haya
                            proporcionado.
                        </p>
                        <p>
                            6.4 En caso de que la información proporcionada en la
                            solicitud sea insuficiente o errónea, o bien, no se acompañen
                            los documentos necesarios, dentro de los 5 (cinco) días
                            hábiles siguientes a la recepción de la solicitud, podremos
                            requerirle que aporte los elementos o documentos necesarios
                            para dar trámite a la misma. Usted contará con 10 (diez) días
                            hábiles para atender el requerimiento, contados a partir del
                            día siguiente en que lo haya recibido. De no dar respuesta en
                            dicho plazo, se tendrá por no presentada la solicitud
                            correspondiente.
                        </p>
                        <p>
                            6.5 Le comunicaremos la determinación adoptada en un plazo
                            máximo de 20 (veinte) días hábiles contados desde la fecha en
                            que se recibió la solicitud (o, en su caso, desde el día
                            siguiente en que usted haya atendido el requerimiento de
                            información), a efecto de que, si resulta procedente, se haga
                            efectiva la misma dentro de los 15 (quince) días hábiles
                            siguientes a la fecha en que se comunique la respuesta. Los
                            plazos antes referidos únicamente podrán ser ampliados de
                            conformidad con la normatividad aplicable, esto es, por una
                            sola ocasión y por causa justificada.
                        </p>
                        <p> 7. ¿Cómo puedo revocar mi consentimiento?</p>
                        <p>
                            7.1 Usted puede revocar el consentimiento que, en su caso, nos
                            haya otorgado para el tratamiento de sus Datos Personales. Sin
                            embargo, es importante que tenga en cuenta que no en todos los
                            casos podremos atender su solicitud o concluir el uso de forma
                            inmediata, ya que es posible que por alguna obligación legal
                            requiramos seguir tratando sus Datos Personales. Asimismo,
                            usted deberá considerar que, para ciertos fines, la revocación
                            de su consentimiento implicará que no le podamos seguir
                            prestando el servicio que nos solicitó, o la conclusión de su
                            relación con nosotros. Usted podrá revocar su consentimiento
                            siguiendo el mismo procedimiento descrito anteriormente
                            relacionado con los derechos ARCO.
                        </p>
                        <p> 8. ¿Cómo puedo limitar el uso o divulgación de mis datos?</p>
                        <p>
                            8.1 Usted también podrá ejercer su derecho de limitar el uso o
                            divulgación de su información siguiendo el mismo procedimiento
                            descrito anteriormente relacionado con los derechos ARCO.
                        </p>
                        <p>
                            9. Uso de Cookies, web beacons u otras tecnologías similares.
                        </p>
                        <p>
                            9.1 Le informamos que, a través de nuestro sitio en internet
                            no utilizamos cookies, web beacons ni otras tecnologías
                            similares. 10. Autoridad Garante.
                        </p>
                        <p>
                            10.1 Si usted considera que su derecho a la protección de sus
                            datos personales ha sido lesionado por alguna conducta u
                            omisión por parte de BMS, o presume alguna violación a las
                            disposiciones en la Ley Federal de Datos Personales en
                            Posesión de los Particulares, su Reglamento y demás
                            ordenamientos aplicables, podrá interponer su inconformidad o
                            denuncia ante el Instituto Nacional de Transparencia, Acceso a
                            la Información y Protección de Datos Personales (INAI). Para
                            mayor información le sugerimos visitar su página oficial de
                            internet www.inai.org.mx.
                        </p>
                        <p> 11. Ley Aplicable de Jurisdicción.</p>
                        <p>
                            11.1 Las partes expresan que el presente aviso, se regirá por
                            las disposiciones legales aplicables en la República Mexicana,
                            en especial, por lo dispuesto en la Ley Federal de Protección
                            de Datos Personales en Posesión de los Particulares, su
                            Reglamento y la demás normativa vinculada aplicable.
                        </p>
                        <p> 12. Cambios al Aviso de Privacidad.</p>
                        <p>
                            12.1 Cualquier cambio al presente, será publicada en nuestro
                            sitio en Internet www.vamoscontigo.com.mx/privacidad
                        </p>
                        <p> Fecha de actualización: 07 de julio de 2023. </p>
                        <p>
                            Para leer el Aviso Legal y Términos y Condiciones:
                            https://www.bms.com/mx/legal-notice.html
                        </p>
                    </Container>
                </main>
                <FooterMain />
            </div>
        </div>
    )
}

export default TermsAndConditions
