//# Root Reducer

import { combineReducers } from 'redux'

import { authReducer } from './auth'
import { navigationReducer } from './navigation'
import { pagesReducer } from './pages'
import { settingsReducer } from './settings'

export const rootReducer = combineReducers({
    auth: authReducer,
    navigation: navigationReducer,
    pages: pagesReducer,
    settings: settingsReducer,
})
