import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider, useSelector } from 'react-redux'
import { MantineProvider } from '@mantine/core'
import store from './store/index'
import { mantineTheme } from './assets/themes/mainTheme'
import { Notifications } from '@mantine/notifications'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'

const RootComponent = () => {
    const colorScheme = useSelector((state) => state.settings.colorScheme)
    const globalFontSize = useSelector((state) => state.settings.globalFontSize)
    return (
        <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            withCSSVariables
            theme={mantineTheme(colorScheme, globalFontSize)}
        >
            <BrowserRouter>
                <Notifications
                    position='bottom-right'
                    zIndex={99999}
                    limit={8}
                />
                <App />
            </BrowserRouter>
        </MantineProvider>
    )
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RootComponent />
        </Provider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
