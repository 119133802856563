import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authLogout } from 'store/actions/auth'
import { settingsSetColorScheme } from 'store/actions/settings'
import { settingsSetGlobalFontSize } from 'store/actions/settings'
import { Menu, Button, useMantineTheme } from '@mantine/core'
import {
    IconLogout,
    IconMoonStars,
    IconSun,
    IconTypography,
} from '@tabler/icons-react'
import { buttonSettingsMenuTheme } from 'assets/themes/buttons'

const SettingsMenu = () => {
    const theme = useMantineTheme()
    const { colorScheme } = useSelector((state) => state.settings)
    const { globalFontSize } = useSelector((state) => state.settings)
    const dispatch = useDispatch()
    const [checked, setChecked] = useState(
        colorScheme === 'light' ? false : true
    )
    useEffect(() => {
        if (colorScheme === 'light' && checked) {
            dispatch(settingsSetColorScheme('dark'))
        }
        if (colorScheme === 'dark' && !checked) {
            dispatch(settingsSetColorScheme('light'))
        }
    }, [checked, colorScheme, dispatch])

    const setGlobalFontSize = (fontSize) => {
        dispatch(settingsSetGlobalFontSize(fontSize))
    }

    return (
        <Menu shadow='md' radius='md'>
            <Menu.Target>
                <Button styles={buttonSettingsMenuTheme(theme)}>
                    Opciones
                </Button>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Label>Sesión</Menu.Label>
                <Menu.Item
                    icon={<IconLogout color='purple' size={16} />}
                    onClick={() => dispatch(authLogout())}
                >
                    Cerrar Sesión
                </Menu.Item>
                <Menu.Divider />
                <Menu.Label>Modo de color</Menu.Label>
                <Menu.Item
                    icon={<IconSun size={16} color='orange' />}
                    onClick={() => setChecked(false)}
                >
                    Tema Claro
                </Menu.Item>
                <Menu.Item
                    icon={<IconMoonStars size={16} color='darkGrey' />}
                    onClick={() => setChecked(true)}
                >
                    Tema Oscuro
                </Menu.Item>

                <Menu.Divider />

                <Menu.Label>Fuente de lectura</Menu.Label>
                <Menu.Item
                    icon={<IconTypography size={12} />}
                    onClick={() => setGlobalFontSize('xs')}
                    color={globalFontSize === 'xs' ? 'purple' : ''}
                >
                    Muy pequeño
                </Menu.Item>
                <Menu.Item
                    icon={<IconTypography size={14} />}
                    onClick={() => setGlobalFontSize('sm')}
                    color={globalFontSize === 'sm' ? 'purple' : ''}
                >
                    Pequeño
                </Menu.Item>
                <Menu.Item
                    icon={<IconTypography size={16} />}
                    onClick={() => setGlobalFontSize('md')}
                    color={globalFontSize === 'md' ? 'purple' : ''}
                >
                    Normal
                </Menu.Item>
                <Menu.Item
                    icon={<IconTypography size={18} />}
                    onClick={() => setGlobalFontSize('lg')}
                    color={globalFontSize === 'lg' ? 'purple' : ''}
                >
                    Grande
                </Menu.Item>
                <Menu.Item
                    icon={<IconTypography size={20} />}
                    onClick={() => setGlobalFontSize('xl')}
                    color={globalFontSize === 'xl' ? 'purple' : ''}
                >
                    Muy grande
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    )
}

export default SettingsMenu
