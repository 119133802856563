import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { Link } from 'react-router-dom'
import { BackgroundImage, NavLink, useMantineTheme } from '@mantine/core'
import navLinkTheme from 'assets/themes/navLinkTheme'
import styles from './styles.module.scss'

const SubjectBlock = ({ aspectRatio, config, data, orientation }) => {
    console.log('🚀 ~ SubjectBlock ~ aspectRatio:', aspectRatio)
    const theme = useMantineTheme()
    const { textFeatureBlock } = navLinkTheme(theme)
    const { level, slug: linkUrl } = config
    const { metaDescription, title, summary, link } = data
    const { src: imageSrc } = data.media.coverImage
    return orientation === 'vertical' ? (
        <div className={styles.container}>
            <BackgroundImage src={imageSrc} sx={{ aspectRatio }} />
            <header>
                <div className={styles.title}>{title}</div>
            </header>
            <div className={styles.summary}>{parse(summary || '')}</div>
            {link && link.label && (
                <footer>
                    <NavLink
                        component={Link}
                        label={link.label}
                        styles={textFeatureBlock}
                        to={linkUrl}
                    />
                </footer>
            )}
        </div>
    ) : (
        <article className={styles.horizontalLayout}>
            <div className={styles.imageContainer}>
                <BackgroundImage src={imageSrc} sx={{ aspectRatio }} />
            </div>
            <div className={styles.infoContainer}>
                <header>
                    <div className={styles.title}>{title}</div>
                </header>
                <div className={styles.summary}>{summary}</div>
                {link && link.label && (
                    <footer>
                        <NavLink
                            component={Link}
                            label={link.label}
                            styles={textFeatureBlock}
                            to={linkUrl}
                        />
                    </footer>
                )}
            </div>
        </article>
    )
}

export default SubjectBlock

SubjectBlock.propTypes = {
    linkLabel: PropTypes.string,
    linkUrl: PropTypes.string,
    summary: PropTypes.string,
    title: PropTypes.string.isRequired,
    orientation: PropTypes.string.isRequired,
}

SubjectBlock.defaultProps = {
    linkLabel: 'Link label',
    linkUrl: 'https://www.bms.com',
    summary: 'Block summary',
    title: 'Block title',
    orientation: 'vertical',
}
