import styles from './styles.module.scss'
const HeaderPage = ({ title, summary, theme = 'themeAmber' }) => {
    return (
        <div className={`${styles.container} ${styles[theme]}`}>
            <div className={styles.content}>
                <div className={styles.title}>{title}</div>
                {summary && <div className={styles.subtitle}>{summary}</div>}
            </div>
        </div>
    )
}

export default HeaderPage
