import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getNavigationService } from 'services/getNavigationService'
import { getNavigation } from 'store/actions/navigation'
export const useNavigationMain = () => {
    const navigation = useSelector((state) => state.navigation)
    const dispatch = useDispatch()

    useEffect(() => {
        if (navigation === null) {
            const setNavigation = async () => {
                const navigation = await getNavigationService()
                dispatch(getNavigation(navigation))
            }
            setNavigation()
        }
    }, [dispatch, navigation])
    /* const links = [
        {
            label: 'Inicio',
            target: '_blank',
            url: '/inicio',
        },
        {
            label: 'Sobre el programa',
            target: '_self',
            url: '/sobre-el-programa',
        },
        {
            label: 'Sobre mi enfermedad',
            target: '_self',
            url: '/sobre-mi-enfermedad',
        },
        {
            label: 'Sitios de interés',
            target: '_blank',
            url: '/sitios-de-interes',
        },
        {
            label: 'Preguntas frecuentes',
            target: '_blank',
            url: '/preguntas-frecuentes',
        },
        {
            label: 'Mi monitora',
            target: '_blank',
            url: '/mi-monitora',
        },
        {
            label: 'Moni me guía',
            target: '_blank',
            url: '/moni-me-guia',
        },
    ] */
    return {
        links: navigation ? navigation.main : [],
    }
}
