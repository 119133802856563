import React from 'react'
import { Container } from '@mantine/core'
import HeaderMain from 'components/UI/Headers/HeaderMain/HeaderMain'
import NavigationMain from 'components/UI/NavigationMain/NavigationMain'
import FooterMain from 'components/UI/FooterMain/FooterMain'
import HeaderPage from 'components/UI/Headers/HeaderPage/HeaderPage'
import BreadcrumbsMain from 'components/UI/Breadcrums/BreadcrumsMain/BreadcrumsMain'
import CardsContainer from 'components/UI/Containers/CardsContainer/CardsContainer'
import StoryCard from 'components/UI/Cards/StoryCard/StoryCard'
//import useAboutMyDisease from './hooks/useAboutMyDisease'
import useScreen from 'hooks/useScreen'
import styles from './styles.module.scss'
import PageLoader from 'components/UI/PageLoader/PageLoader'

const AboutMyDisease = () => {
    const { pageData } = useScreen()
    if (!pageData) {
        return <PageLoader />
    } else {
        const { data } = pageData
        return (
            <div className={styles.container}>
                <div className={styles.layout}>
                    <HeaderMain />
                    <NavigationMain />
                    <main>
                        <HeaderPage
                            title={data.title}
                            summary={data.summary}
                            theme='themePeach'
                        />
                        <BreadcrumbsMain />
                        {pageData.posts.length === 0 ? (
                            <Container size='1280px' px={0} py='2x'>
                                <div className={styles.noContent}>
                                    Actualmente no existen publicaciones
                                    relacionadas con su indicación terapeutica o
                                    medicamento.
                                </div>
                            </Container>
                        ) : (
                            <>
                                <CardsContainer
                                    aspectRatio='3/2'
                                    cols={2}
                                    component={<StoryCard />}
                                    items={pageData.posts.slice(0, 2)}
                                />
                                <CardsContainer
                                    aspectRatio='16/9'
                                    cols={3}
                                    component={<StoryCard />}
                                    items={pageData.posts.slice(2)}
                                />
                            </>
                        )}
                    </main>
                    <FooterMain />
                </div>
            </div>
        )
    }
}

export default AboutMyDisease
