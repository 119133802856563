//# Authentication Actions
import {
    authServiceLogin,
    authServiceLogout,
    authServiceValidate,
} from 'services/authServices'
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_VALIDATE } from '../constants/auth'

//* Auth Login
export const authLogin = (payload) => {
    return async function (dispatch) {
        const response = await authServiceLogin(payload)
        if (response.status) {
            dispatch({
                type: AUTH_LOGIN,
                payload: response.data,
            })
        }
    }
}

//* Auth Validate
export const authValidate = () => {
    return async function (dispatch) {
        const response = await authServiceValidate()
        dispatch({
            type: AUTH_VALIDATE,
            payload: response.data,
        })
    }
}

//* Auth Logout
export const authLogout = () => {
    return async function (dispatch) {
        const response = await authServiceLogout()
        dispatch({
            type: AUTH_LOGOUT,
            payload: response.data,
        })
    }
}
