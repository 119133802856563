import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import PublicPage from './components/pages/PublicPage/PublicPage'
import PrivatePage from './components/pages/PrivatePage/PrivatePage'

const ScrollToTop = () => {
    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return null
}

function App() {
    return (
        <React.Fragment>
            <ScrollToTop />
            <Routes>
                <Route path='/error-404' exact element={<PublicPage />} />
                <Route
                    path='/consentimiento-informado'
                    exact
                    element={<PublicPage />}
                />
                <Route path='/mantenimiento' exact element={<PublicPage />} />
                <Route path='/iniciar-sesion' exact element={<PublicPage />} />
                <Route
                    path='/aviso-de-privacidad'
                    exact
                    element={<PublicPage />}
                />
                <Route
                    path='/terminos-y-condiciones'
                    exact
                    element={<PublicPage />}
                />
                <Route path='/' exact element={<PublicPage />} />
                {/* <Route path='/inicio' element={<PrivatePage template='inicio' />} /> */}
                {/* URL Level 1 */}
                <Route path='/:url1' element={<PrivatePage />} />
                {/* URL Level 2 */}
                <Route path='/:url1/:url2' element={<PrivatePage />} />
                {/* URL Level 3 */}
                <Route path='/:url1/:url2/:url3' element={<PrivatePage />} />
            </Routes>
        </React.Fragment>
    )
}

export default App
