import React from 'react'
import parse from 'html-react-parser'
import { useSelector } from 'react-redux'
import { Grid } from '@mantine/core'
import Logo from 'components/UI/Logo/Logo'
import PageLoader from 'components/UI/PageLoader/PageLoader'
import ContactCard from 'components/UI/Cards/ContactCard/ContactCard'
import styles from './styles.module.scss'
const Maintenance = ({ pageData, projectAuthNumber, instanceCountryCode }) => {
    const { colorScheme } = useSelector((state) => state.settings)
    if (!pageData) {
        return <PageLoader />
    } else {
        const { data, referencedPosts } = pageData
        return (
            <div className={styles.layout}>
                <div className={styles.loginCard}>
                    <div className={styles.logo}>
                        <Logo logo='bmsLogo' theme={colorScheme} />
                    </div>
                    <div className={styles.title}>{data.title}</div>
                    <div className={styles.summary}>
                        {parse(data.summary || '')}
                    </div>
                    <Grid grow gutter='sm' sx={{ width: '100%' }}>
                        {referencedPosts[0] &&
                            referencedPosts[0].items.map((card) => (
                                <Grid.Col key={card._id} sm={12} md={6} lg={12}>
                                    <ContactCard data={card} />
                                </Grid.Col>
                            ))}
                    </Grid>

                    <div className={styles.authNumber}>
                        {instanceCountryCode === 'mx'
                            ? `Número de aviso SSA: ${projectAuthNumber}`
                            : `Autorización No. SSA: ${projectAuthNumber}`}
                    </div>
                </div>
                <div className={styles.slide}>&nbsp;</div>
            </div>
        )
    }
}
export default Maintenance
