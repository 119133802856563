import styles from './styles.module.scss'
const Hero = ({ aspectRatio, imageSource }) => {
    const bucketUrl = process.env.REACT_APP_BUCKET_URL
    const instance = process.env.REACT_APP_INSTANCE
    return (
        <div
            className={styles.container}
            style={{
                aspectRatio: aspectRatio,
                backgroundImage: `url(${imageSource})`,
            }}
        ></div>
    )
}
export default Hero
