const rootStyles = {
    borderRadius: '16rem',
    boxShadow: '0 0 1px 0px white inset, 0 0 1px 0px white',
    fontWeight: 'normal',
    height: 'auto',
    padding: '.5rem 1rem',
    width: '100%',
    span: {
        overflow: 'visible',
    },
}
export const buttonTheme = (mantineTheme) => {
    const rootStyles = {
        borderRadius: mantineTheme.radius.md,
    }
    return {
        primary: {
            root: {
                ...rootStyles,
            },
        },
    }
}
export const buttonMainTheme = (theme) => {
    return {
        root: {
            ...rootStyles,
            backgroundColor: `${theme.colors.purple[1]}`,
            border: `solid 1px ${theme.colors.purple[1]}`,
            color: 'white',
            fontSize: `${theme.fontSizes.sm}`,
            transition: '250ms',
            transitionTimingFunction: 'ease-in-out',
            '&:hover': {
                backgroundColor: 'white',
                border: `solid 1px ${theme.colors.purple[1]}`,
                color: `${theme.colors.purple[1]}`,
            },
        },
        leftIcon: {
            marginRight: '0.5rem',
        },
        rightIcon: {
            marginLeft: '0.5rem',
        },
    }
}
export const buttonSubmitMainTheme = (theme) => {
    return {
        root: {
            ...rootStyles,
            backgroundColor: theme.colors.purple[1],
            border: `solid 1px ${theme.colors.purple[1]}`,
            color: 'white',
            fontSize: `${theme.fontSizes.sm}`,
            transitionTimingFunction: 'ease-in-out',
            transition: '250ms',
            '&:hover': {
                backgroundColor: theme.colors.gray[0],
            },
        },
        leftIcon: {
            marginRight: '0.5rem',
        },
        rightIcon: {
            marginLeft: '0.5rem',
        },
    }
}

export const buttonSettingsMenuTheme = (theme) => {
    return {
        root: {
            ...rootStyles,
            backgroundColor: 'transparent',
            color: theme.colors.darkGrey[1],
            fontSize: `${theme.fontSizes.sm}`,
            transitionTimingFunction: 'ease-in-out',
            transition: '250ms',
            boxShadow: 'none',
            '&:hover': {
                backgroundColor: 'transparent',
                color: theme.colors.purple[1],
            },
        },
        leftIcon: {
            marginRight: '0.5rem',
        },
        rightIcon: {
            marginLeft: '0.5rem',
        },
    }
}
