// Public Templates
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { getPageService } from 'services/getPageService'
import { kebabCaseToCamelCase } from 'utils/utils'

import InformedConsent from 'components/screens/InformedConsent/InformedConsent'
import Login from 'components/screens/Login/Login'
import Maintenance from 'components/screens/Maintenance/Maintenance'
import Error404 from 'components/screens/Error404/Error404'
import GenericScreen from 'components/screens/GenericScreen/GenericScreen'

const isMaintenance = parseInt(process.env.REACT_APP_MAINTENANCE, 10) === 1
const projectAuthNumber = process.env.REACT_APP_AUTH_NUMBER
const instanceCountryCode = process.env.REACT_APP_COUNTRY_CODE

const templates = {
    error404: <Error404 />,
    iniciarSesion: <Login />,
    mantenimiento: <Maintenance />,
    consentimientoInformado: <InformedConsent />,
    avisoDePrivacidad: <GenericScreen />,
    terminosYCondiciones: <GenericScreen />,
}

export const usePublicPage = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [loading, setLoading] = useState(true)
    const [fetchedData, setFetchedData] = useState(null)
    const currentPage = location.pathname.slice(1)
    const isLogged = useSelector((state) => state.auth.token)

    useEffect(() => {
        if (isMaintenance === true) {
            navigate('/mantenimiento', { replace: true })
        }
    }, [navigate])

    useEffect(() => {
        if (isLogged) {
            const publicPages = [
                'aviso-de-privacidad',
                'terminos-y-condiciones',
                'consentimiento-informado',
            ]
            if (publicPages.includes(currentPage)) {
                navigate(`/${currentPage}`, { replace: true })
            } else {
                navigate('/inicio', { replace: true })
            }
        }
    }, [currentPage, isLogged, navigate])

    useEffect(() => {
        if (currentPage === '') {
            navigate('/iniciar-sesion', { replace: true })
        } else {
            const getPageData = async () => {
                const pageData = await getPageService(currentPage)
                setFetchedData(pageData)
                if (pageData) setLoading(false)
            }
            getPageData()
        }
    }, [loading, currentPage, navigate])

    const template = currentPage
        ? templates[kebabCaseToCamelCase(currentPage)]
        : templates['iniciarSesion']

    return {
        isLogged,
        isMaintenance,
        template,
        pageData: fetchedData,
        projectAuthNumber,
        instanceCountryCode,
    }
}
