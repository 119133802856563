const accordionTheme = (theme) => {
    const frequentlyAskedQuestions = {
        chevron: {
            color: theme.colors.purple[0],
            '&[data-rotate]': {
                transform: 'rotate(45deg)',
            },
        },
        control: { color: theme.colors.darkGrey[0] },
        content: {
            color: theme.colors.darkGrey[0],
            paddingTop: theme.spacing.md,
        },
        item: {
            '&[data-active]': {
                backgroundColor: theme.colors.almond[9],
            },
        },
    }
    return {
        frequentlyAskedQuestions,
    }
}
export default accordionTheme
