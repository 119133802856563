import React from 'react'

import HeaderMain from 'components/UI/Headers/HeaderMain/HeaderMain'
import NavigationMain from 'components/UI/NavigationMain/NavigationMain'
import FooterMain from 'components/UI/FooterMain/FooterMain'
import HeaderPage from 'components/UI/Headers/HeaderPage/HeaderPage'
import BreadcrumbsMain from 'components/UI/Breadcrums/BreadcrumsMain/BreadcrumsMain'
import CardsContainer from 'components/UI/Containers/CardsContainer/CardsContainer'
import StoryCard from 'components/UI/Cards/StoryCard/StoryCard'
import useVideos from './hooks/useVideos'
import styles from './styles.module.scss'
const Videos = () => {
    const { breadcrumbsData, pageData, videos } = useVideos()
    const { title, summary, theme } = pageData
    return (
        <div className={styles.container}>
            <div className={styles.layout}>
                <HeaderMain />
                <NavigationMain />
                <main>
                    <HeaderPage title={title} summary={summary} theme={theme} />
                    <BreadcrumbsMain items={breadcrumbsData} />
                    <CardsContainer
                        aspectRatio='16/9'
                        cols={3}
                        component={<StoryCard />}
                        items={videos}
                    />
                </main>
                <FooterMain />
            </div>
        </div>
    )
}

export default Videos
