//# Pages services
export const getPostService = async (slug) => {
    const apiUrl = process.env.REACT_APP_API_URL
    try {
        const token = JSON.parse(localStorage.token)
        const route = `posts/${slug}`
        const endPoint = `${apiUrl}${route}`

        const headers = new Headers()
        headers.append('Content-Type', 'application/json, text/plain')
        headers.append('Authorization', `Bearer ${token}`)

        const response = await fetch(`${endPoint}`, {
            headers: headers,
            method: 'GET',
        })
        const result = await response.json()
        if (result.status) {
            return result.data
        }
    } catch (error) {
        throw new Error(error)
    }
}
