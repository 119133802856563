import { posts } from '../../../../data/appData'
import { useParams } from 'react-router-dom'
import { getBreadcrumsData, getPageData } from 'utils/utils'
const useVideos = () => {
    const params = useParams()
    const breadcrumbsData = getBreadcrumsData(params, posts)
    const pageData = getPageData(posts, params)
    const videos = posts
        .filter((video) => pageData.content.posts.includes(video.url))
        .map((video) => {
            return {
                title: video.title,
                summary: video.summary,
                imageSource: video.media.coverImage.src,
                linkLabel: `Más informacion >`,
                linkUrl: `${video.url}`,
            }
        })
    return {
        breadcrumbsData,
        pageData,
        videos,
    }
}
export default useVideos
