import React from 'react'
import parse from 'html-react-parser'
import { Accordion, Container, Tabs, useMantineTheme } from '@mantine/core'
import { IconPlus } from '@tabler/icons-react'
import PageLoader from 'components/UI/PageLoader/PageLoader'
import HeaderMain from 'components/UI/Headers/HeaderMain/HeaderMain'
import NavigationMain from 'components/UI/NavigationMain/NavigationMain'
import FooterMain from 'components/UI/FooterMain/FooterMain'
import HeaderPage from 'components/UI/Headers/HeaderPage/HeaderPage'
import BreadcrumbsMain from 'components/UI/Breadcrums/BreadcrumsMain/BreadcrumsMain'
import accordionTheme from 'assets/themes/accordionTheme'
import tabsTheme from 'assets/themes/tabsTheme'
import useScreen from 'hooks/useScreen'
import styles from './styles.module.scss'
const FrequentlyAskedQuestions = () => {
    const theme = useMantineTheme()
    const getFaq = (questions) => {
        const result = [
            ...new Set(questions.map((question) => question.data.categoryName)),
        ].map((category) => {
            return {
                categoryName: category,
                items: questions.filter(
                    (question) => question.data.categoryName === category
                ),
            }
        })
        return result
    }
    const { instance, countryCode, pageData } = useScreen()
    const { frequentlyAskedQuestions } = accordionTheme(theme)
    const { faqContainer } = tabsTheme(theme)
    if (!pageData) {
        return <PageLoader />
    } else {
        const { data, referencedPosts, posts } = pageData
        const questions =
            countryCode !== 'mx'
                ? getFaq(referencedPosts[0].items)
                : getFaq(posts)
        console.log('🚀 ~ FrequentlyAskedQuestions ~ questions:', questions)
        return (
            <div className={styles.container}>
                <div className={styles.layout}>
                    <HeaderMain />
                    <NavigationMain />
                    <main>
                        <HeaderPage
                            title={data.title}
                            summary={data.summary}
                            theme='themeAlmond'
                        />
                        <BreadcrumbsMain />
                        <Container
                            size='1280px'
                            px={0}
                            py='md'
                            className={styles.contentMd}
                        >
                            <Tabs
                                defaultValue={questions[0].categoryName}
                                orientation='vertical'
                                styles={faqContainer}
                            >
                                <Tabs.List>
                                    {questions &&
                                        questions.map((question) => (
                                            <Tabs.Tab
                                                value={question.categoryName}
                                            >
                                                {question.categoryName}
                                            </Tabs.Tab>
                                        ))}
                                </Tabs.List>
                                {questions &&
                                    questions.map((question) => (
                                        <Tabs.Panel
                                            value={question.categoryName}
                                        >
                                            <Accordion
                                                styles={
                                                    frequentlyAskedQuestions
                                                }
                                                defaultValue={
                                                    question.items[0].data
                                                        .question
                                                }
                                                chevron={
                                                    <IconPlus size='1rem' />
                                                }
                                            >
                                                {question.items &&
                                                    question.items.map(
                                                        (item, index) => (
                                                            <Accordion.Item
                                                                key={index}
                                                                value={
                                                                    item.data
                                                                        .question
                                                                }
                                                            >
                                                                <Accordion.Control>
                                                                    {
                                                                        item
                                                                            .data
                                                                            .question
                                                                    }
                                                                </Accordion.Control>
                                                                <Accordion.Panel>
                                                                    {parse(
                                                                        item
                                                                            .data
                                                                            .answer
                                                                    )}
                                                                </Accordion.Panel>
                                                            </Accordion.Item>
                                                        )
                                                    )}
                                            </Accordion>
                                        </Tabs.Panel>
                                    ))}
                            </Tabs>
                        </Container>
                        <Container
                            size='1280px'
                            px={0}
                            py='md'
                            className={styles.contentSm}
                        >
                            FAQ Mobile
                        </Container>
                    </main>
                    <FooterMain />
                </div>
            </div>
        )
    }
}

export default FrequentlyAskedQuestions
