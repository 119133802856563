import { posts } from '../../../../data/appData'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getBreadcrumsData, getPageData } from 'utils/utils'
const useArticlesAndMaterials = () => {
    const instance = process.env.REACT_APP_INSTANCE
    const params = useParams()
    const { url1, url2 } = params
    const { pages } = useSelector((state) => state.pages)
    const breadcrumbsData = getBreadcrumsData(params, posts)
    const pageData = getPageData(pages, url2 ? url2 : url1)

    return {
        instance,
        breadcrumbsData,
        pageData: pageData ? pageData.data : null,
        articles: pageData ? pageData.data.content.articles : null,
    }
    /* const params = useParams()
    const pageData = getPageData(posts, params)
    const articles = posts
        .filter((article) => pageData.content.articles.includes(article.url))
        .map((article) => {
            return {
                metaDescription: article.metaDescription,
                title: article.title,
                summary: article.summary,
                imageSource: article.media.coverImage.src,
                linkLabel: `Más informacion >`,
                linkUrl: `${article.url}`,
            }
        })

    return {
        pageData,
        articles,
    } */
}
export default useArticlesAndMaterials
