import React, { useState } from 'react'
import styles from './styles.module.scss'
import InputText from '../../Inputs/InputText/InputText'
import { ActionIcon, Avatar, Button } from '@mantine/core'
import { IconSend, IconX } from '@tabler/icons-react'
const MyMonitor = () => {
    const avatarSrc =
        'https://bmspspbucket.s3.amazonaws.com/mx/images/mx-my-monitor-widget-avatar.png'
    const [isOpen, setIsOpen] = useState(false)
    if (!isOpen) {
        return (
            <div className={styles.widgetTrigger}>
                <Button
                    color='purple'
                    radius='sm'
                    size='md'
                    onClick={() => setIsOpen(true)}
                >
                    <Avatar
                        src={avatarSrc}
                        alt='Moni Me Guia'
                        radius='xl'
                        size='sm'
                        sx={{ marginRight: '.5rem' }}
                    />
                    Moni Me Guia
                </Button>
            </div>
        )
    } else {
        return (
            <div className={styles.container}>
                <div className={styles.widget}>
                    <header>
                        Moni Me Guía
                        <ActionIcon
                            variant='filled'
                            color='purple'
                            radius='sm'
                            size='lg'
                            onClick={() => setIsOpen(false)}
                        >
                            <IconX size='1.5rem' />
                        </ActionIcon>
                    </header>
                    <section>
                        <div className={styles.chatLine}>
                            <Avatar
                                src={avatarSrc}
                                alt='Moni Me Guia'
                                radius='xl'
                                size='lg'
                            />
                            <div className={styles.chatBubble}>
                                !Hola¡ Soy Moni Me Guía la nueva asistente de
                                Bristol Myers Squibb
                            </div>
                        </div>
                    </section>
                    <footer>
                        <div className={styles.prompt}>
                            <InputText
                                autocomplete={false}
                                placeholder='Escriba aquí su mensaje'
                                theme='myMonitor'
                            />
                            <ActionIcon
                                variant='filled'
                                color='purple'
                                radius='sm'
                                size='lg'
                            >
                                <IconSend size='1.125rem' />
                            </ActionIcon>
                        </div>
                    </footer>
                </div>
            </div>
        )
    }
}

export default MyMonitor
