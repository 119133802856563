import React from 'react'
import BreadcrumbsMain from 'components/UI/Breadcrums/BreadcrumsMain/BreadcrumsMain'
import CardsContainer from 'components/UI/Containers/CardsContainer/CardsContainer'
import FooterMain from 'components/UI/FooterMain/FooterMain'
import HeaderMain from 'components/UI/Headers/HeaderMain/HeaderMain'
import HeaderPage from 'components/UI/Headers/HeaderPage/HeaderPage'
import NavigationMain from 'components/UI/NavigationMain/NavigationMain'
import PageLoader from 'components/UI/PageLoader/PageLoader'
import StoryCard from 'components/UI/Cards/StoryCard/StoryCard'
//import useAboutMyTreatment from './hooks/useAboutMyTreatment'
import useScreen from 'hooks/useScreen'
import styles from './styles.module.scss'
const AboutMyTreatment = () => {
    const { pageData } = useScreen()
    if (!pageData) {
        return <PageLoader />
    } else {
        const { data } = pageData
        return (
            <div className={styles.container}>
                <div className={styles.layout}>
                    <HeaderMain />
                    <NavigationMain />
                    <main>
                        <HeaderPage
                            title={data.title}
                            summary={data.summary}
                            theme='themeAmber'
                        />
                        <BreadcrumbsMain />
                        <CardsContainer
                            aspectRatio='16/9'
                            cols={2}
                            component={<StoryCard />}
                            items={pageData.posts.slice(0, 2)}
                        />
                        <CardsContainer
                            aspectRatio='3/2'
                            cols={3}
                            component={<StoryCard />}
                            items={pageData.posts.slice(2)}
                        />
                    </main>
                    <FooterMain />
                </div>
            </div>
        )
    }
}

export default AboutMyTreatment
