import { showNotification } from '@mantine/notifications'

import {
    IconInfoCircle,
    IconCheck,
    IconExclamationMark,
    IconX,
} from '@tabler/icons-react'

import {
    notificationPrimary,
    notificationSuccess,
    notificationWarning,
    notificationDanger,
} from '../assets/themes/notificationsTheme.js'

export const showNotifications = ({
    type,
    message,
    autoClose = true,
    title = 'Error',
}) => {
    switch (type) {
        case 'danger':
            showNotification({
                autoClose,
                icon: <IconX size={20} />,
                message,
                title: title,
                styles: (theme) => notificationDanger(theme),
            })
            break

        case 'warning':
            showNotification({
                autoClose,
                icon: <IconExclamationMark size={16} />,
                message,
                title: 'Precaución',
                styles: (theme) => notificationWarning(theme),
            })
            break

        case 'success':
            showNotification({
                autoClose,
                icon: <IconCheck size={16} />,
                message,
                title: title ? title : 'Exito',
                styles: (theme) => notificationSuccess(theme),
            })
            break

        default:
            showNotification({
                autoClose,
                icon: <IconInfoCircle size={16} />,
                message,
                title: 'Información',
                styles: (theme) => notificationPrimary(theme),
            })
    }
}
