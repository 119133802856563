import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useDisclosure } from '@mantine/hooks'
import { NavLink, useMantineTheme } from '@mantine/core'
import Modal from 'components/UI/Modals/Modal'
import styles from './styles.module.scss'
import navLinkTheme from 'assets/themes/navLinkTheme'
const instanceCountryCode = process.env.REACT_APP_COUNTRY_CODE

const TextFeatureBlock = ({ config, data }) => {
    const [opened, { open, close }] = useDisclosure()
    const [targetUrl, setTargetUrl] = useState('')
    const handleClose = () => {
        close()
    }
    const { navigation } = config
    const { summary, title } = data
    const theme = useMantineTheme()
    const { textFeatureBlock } = navLinkTheme(theme)
    useEffect(() => {
        if (opened) {
            setTargetUrl(navigation.slug)
        }
    }, [navigation, navigation.slug, opened, setTargetUrl])
    return (
        <div className={styles.container}>
            <header>
                <div className={styles.separator}></div>
                <div className={styles.title}>{title}</div>
            </header>
            <div className={styles.summary}>{summary}</div>
            <footer>
                {instanceCountryCode === 'mx' ? (
                    <NavLink
                        label={navigation.label}
                        styles={textFeatureBlock}
                        onClick={open}
                    />
                ) : (
                    <NavLink
                        component={Link}
                        label={navigation.label}
                        styles={textFeatureBlock}
                        onClick={open}
                        to={navigation.slug}
                    />
                )}
            </footer>
            <Modal
                opened={opened}
                onClosed={close}
                handleClose={handleClose}
                targetUrl={targetUrl}
            />
        </div>
    )
}
export default TextFeatureBlock

TextFeatureBlock.propTypes = {
    linkLabel: PropTypes.string,
    linkUrl: PropTypes.string,
    summary: PropTypes.string,
    title: PropTypes.string.isRequired,
}

TextFeatureBlock.defaultProps = {
    linkLabel: 'Link label',
    linkUrl: 'https://www.bms.com',
    summary: 'Block summary',
    title: 'Block title',
}
