import { showNotifications } from 'utils/showNotifications'

const instance = process.env.REACT_APP_INSTANCE
const apiUrl = process.env.REACT_APP_API_URL
const env = process.env.REACT_APP_ENV

export const authServiceLogin = async (payload) => {
    try {
        const headers = {
            Accept: 'application/json, text/plain',
            'Content-Type': 'application/json;charset=UTF-8',
            env: env,
        }
        const route = 'auth/login/'
        const endPoint = `${apiUrl}${route}`
        const response = await fetch(endPoint, {
            method: 'POST',
            body: JSON.stringify({ ...payload, instance }),
            headers: headers,
        })

        const result = await response.json()

        if (!result.status) {
            showNotifications({
                type: 'danger',
                title: 'Error',
                message: 'Verfique su nombre de usuario y contraseña',
            })
            return {
                status: result.status,
                message: result.message,
            }
        }

        const issued = new Date().toLocaleString()
        const expires = new Date()
        expires.setHours(expires.getHours() + 8).toLocaleString()

        const token = {
            ...result.data,
            issued,
            expires: new Date(expires).toISOString(),
        }

        //TODO change this to more secure method
        localStorage.setItem('token', JSON.stringify(token.token))
        return {
            status: true,
            message: 'Success',
            data: { ...token },
        }
    } catch (error) {
        throw new Error(error)
    }
}

//* Request Auth Validate
export async function authServiceValidate() {
    try {
        const token = JSON.parse(localStorage.token)
        const route = 'auth/validate/'
        const endPoint = `${apiUrl}${route}`
        const headers = new Headers()
        headers.append('Content-Type', 'application/json, text/plain')
        headers.append('Authorization', `Bearer ${token}`)
        headers.append('env', env)

        const response = await fetch(`${endPoint}`, {
            headers: headers,
            method: 'GET',
        })

        const result = await response.json()
        if (result.status) {
            localStorage.setItem('token', JSON.stringify(result.data.token))
            console.log('validation token updated')
        } else {
            localStorage.clear()
        }
        return { status: result.status, data: result.data }
    } catch (e) {
        console.log(e)
    }
}

export const authServiceLogout = async () => {
    localStorage.clear()
    return {
        status: true,
        data: {
            user: null,
            token: null,
        },
    }
}
