import React from 'react'
import {
    ActionIcon,
    BackgroundImage,
    Container,
    Divider,
    Flex,
    Grid,
    Text,
    Title,
} from '@mantine/core'
import PageLoader from 'components/UI/PageLoader/PageLoader'
import HeaderMain from 'components/UI/Headers/HeaderMain/HeaderMain'
import NavigationMain from 'components/UI/NavigationMain/NavigationMain'
import FooterMain from 'components/UI/FooterMain/FooterMain'
import HeaderPage from 'components/UI/Headers/HeaderPage/HeaderPage'
import BreadcrumbsMain from 'components/UI/Breadcrums/BreadcrumsMain/BreadcrumsMain'
import useProfile from './hooks/useProfile'
import useScreen from 'hooks/useScreen'
import styles from './styles.module.scss'
const Profile = () => {
    const { instance, themes, pageData } = useScreen()
    if (!pageData) {
        return <PageLoader />
    } else {
        const { data, referencedPosts } = pageData
        return (
            <div className={styles.container}>
                <div className={styles.layout}>
                    <HeaderMain />
                    <NavigationMain />
                    <main>
                        <HeaderPage
                            title={data.title}
                            summary={data.summary}
                            theme='themeAmber'
                        />
                        <BreadcrumbsMain />
                        <Container size='1280px' px={0} pb='4x'>
                            <Grid gutter='xl'>
                                <Grid.Col md={2} sm={6}>
                                    <BackgroundImage
                                        src={data.media.coverImage.src}
                                        sx={{
                                            aspectRatio: '1/1',
                                        }}
                                    />
                                </Grid.Col>
                                <Grid.Col md={5} sm={6}>
                                    <Title c='darkGrey.0' order={3}>
                                        Información personal
                                    </Title>
                                    <Divider
                                        mt='xs'
                                        size='xs'
                                        sx={(theme) => ({
                                            borderTopColor:
                                                theme.colors.purple[0],
                                        })}
                                    />
                                    <Flex
                                        align='center'
                                        justify='space-between'
                                        sx={(theme) => ({
                                            paddingBlock: theme.spacing.sm,
                                        })}
                                    >
                                        <Text fz='md' c='darkGrey.0'>
                                            Nombres: Nombres del paciente
                                        </Text>
                                    </Flex>
                                    <Divider
                                        size='xs'
                                        sx={(theme) => ({
                                            borderTopColor:
                                                theme.colors.lightGrey[0],
                                        })}
                                    />
                                    <Flex
                                        align='center'
                                        justify='space-between'
                                        sx={(theme) => ({
                                            paddingBlock: theme.spacing.sm,
                                        })}
                                    >
                                        <Text fz='md' c='darkGrey.0'>
                                            Apellidos: Apellidos del paciente
                                        </Text>
                                    </Flex>
                                    <Divider
                                        size='xs'
                                        sx={(theme) => ({
                                            borderTopColor:
                                                theme.colors.lightGrey[0],
                                        })}
                                    />
                                    <Flex
                                        align='center'
                                        justify='space-between'
                                        sx={(theme) => ({
                                            paddingBlock: theme.spacing.sm,
                                        })}
                                    >
                                        <Text fz='md' c='darkGrey.0'>
                                            Edad: Edad del paciente
                                        </Text>
                                    </Flex>
                                    <Divider
                                        size='xs'
                                        sx={(theme) => ({
                                            borderTopColor:
                                                theme.colors.lightGrey[0],
                                        })}
                                    />
                                    <Flex
                                        align='center'
                                        justify='space-between'
                                        sx={(theme) => ({
                                            paddingBlock: theme.spacing.sm,
                                        })}
                                    >
                                        <Text fz='md' c='darkGrey.0'>
                                            Indicación terapeutica: Indicación
                                            terapeutica del paciente
                                        </Text>
                                    </Flex>
                                    <Divider
                                        size='xs'
                                        sx={(theme) => ({
                                            borderTopColor:
                                                theme.colors.lightGrey[0],
                                        })}
                                    />
                                    <Flex
                                        align='center'
                                        justify='space-between'
                                        sx={(theme) => ({
                                            paddingBlock: theme.spacing.sm,
                                        })}
                                    >
                                        <Text fz='md' c='darkGrey.0'>
                                            Medicamento: Medicamento del
                                            paciente
                                        </Text>
                                    </Flex>
                                    <Divider
                                        size='xs'
                                        sx={(theme) => ({
                                            borderTopColor:
                                                theme.colors.lightGrey[0],
                                        })}
                                    />
                                    <Flex
                                        align='center'
                                        justify='space-between'
                                        sx={(theme) => ({
                                            paddingBlock: theme.spacing.sm,
                                        })}
                                    >
                                        <Text fz='md' c='darkGrey.0'>
                                            Médico asignado: Médico asignado al
                                            paciente
                                        </Text>
                                    </Flex>
                                    <Divider
                                        size='xs'
                                        sx={(theme) => ({
                                            borderTopColor:
                                                theme.colors.lightGrey[0],
                                        })}
                                    />
                                    <Flex
                                        align='center'
                                        justify='space-between'
                                        sx={(theme) => ({
                                            paddingBlock: theme.spacing.sm,
                                        })}
                                    >
                                        <Text fz='md' c='darkGrey.0'>
                                            Monitora asignada: Monitora asignada
                                            al paciente
                                        </Text>
                                    </Flex>
                                    <Divider
                                        size='xs'
                                        sx={(theme) => ({
                                            borderTopColor:
                                                theme.colors.lightGrey[0],
                                        })}
                                    />
                                </Grid.Col>
                                <Grid.Col md={5} sm={6}>
                                    <Title c='darkGrey.0' order={3}>
                                        Información de contacto
                                    </Title>
                                    <Divider
                                        mt='xs'
                                        size='xs'
                                        sx={(theme) => ({
                                            borderTopColor:
                                                theme.colors.purple[0],
                                        })}
                                    />
                                    <Flex
                                        align='center'
                                        justify='space-between'
                                        sx={(theme) => ({
                                            paddingBlock: theme.spacing.sm,
                                        })}
                                    >
                                        <Text fz='md' c='darkGrey.0'>
                                            País: México
                                        </Text>
                                    </Flex>
                                    <Divider
                                        size='xs'
                                        sx={(theme) => ({
                                            borderTopColor:
                                                theme.colors.lightGrey[0],
                                        })}
                                    />
                                    <Flex
                                        align='center'
                                        justify='space-between'
                                        sx={(theme) => ({
                                            paddingBlock: theme.spacing.sm,
                                        })}
                                    >
                                        <Text fz='md' c='darkGrey.0'>
                                            Estado: Estado de México
                                        </Text>
                                    </Flex>
                                    <Divider
                                        size='xs'
                                        sx={(theme) => ({
                                            borderTopColor:
                                                theme.colors.lightGrey[0],
                                        })}
                                    />
                                    <Flex
                                        align='center'
                                        justify='space-between'
                                        sx={(theme) => ({
                                            paddingBlock: theme.spacing.sm,
                                        })}
                                    >
                                        <Text fz='md' c='darkGrey.0'>
                                            Teléfono: 718-1234567
                                        </Text>
                                    </Flex>
                                    <Divider
                                        size='xs'
                                        sx={(theme) => ({
                                            borderTopColor:
                                                theme.colors.lightGrey[0],
                                        })}
                                    />
                                    <Flex
                                        align='center'
                                        justify='space-between'
                                        sx={(theme) => ({
                                            paddingBlock: theme.spacing.sm,
                                        })}
                                    >
                                        <Text fz='md' c='darkGrey.0'>
                                            Móvil: 322-1234567
                                        </Text>
                                    </Flex>
                                    <Divider
                                        size='xs'
                                        sx={(theme) => ({
                                            borderTopColor:
                                                theme.colors.lightGrey[0],
                                        })}
                                    />
                                    <Flex
                                        align='center'
                                        justify='space-between'
                                        sx={(theme) => ({
                                            paddingBlock: theme.spacing.sm,
                                        })}
                                    >
                                        <Text fz='md' c='darkGrey.0'>
                                            Correo electrónico:
                                            usuario@gmail.com
                                        </Text>
                                    </Flex>
                                    <Divider
                                        size='xs'
                                        sx={(theme) => ({
                                            borderTopColor:
                                                theme.colors.lightGrey[0],
                                        })}
                                    />
                                </Grid.Col>
                            </Grid>
                        </Container>
                    </main>
                    <FooterMain />
                </div>
            </div>
        )
    }
}

export default Profile
