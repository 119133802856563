import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useMantineTheme } from '@mantine/core'
import { PasswordInput } from '@mantine/core'
import inputPasswordTheme from 'assets/themes/inputPasswordTheme'

const InputPassword = ({
    error,
    handleInputChange,
    icon,
    initialValue,
    label,
    name,
    placeholder,
    required,
    theme,
    variant,
}) => {
    const [value, setValue] = useState(initialValue)
    const previousValue = useRef(initialValue)
    const mantineTheme = useMantineTheme()
    const inputTheme = inputPasswordTheme(mantineTheme, theme)

    useEffect(() => {
        if (previousValue.current !== value) {
            handleInputChange(name, value)
            previousValue.current = value
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, handleInputChange, name])

    return (
        <PasswordInput
            icon={icon}
            label={label}
            name={name}
            onChange={(event) => setValue(event.currentTarget.value)}
            placeholder={placeholder}
            styles={inputTheme}
            value={value}
            variant={variant}
            withAsterisk={required || false}
            error={error || false}
        />
    )
}

export default InputPassword

InputPassword.propTypes = {
    icon: PropTypes.node,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    name: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    theme: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    handleInputChange: PropTypes.func,
}

InputPassword.defaultProps = {
    icon: null,
    label: false,
    name: 'Input Name',
    placeholder: 'Input Placeholder',
    required: false,
    theme: false,
    handleInputChange: () => {},
}
