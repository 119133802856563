import React from 'react'
import { useSelector } from 'react-redux'
import parse from 'html-react-parser'
import { BackgroundImage, Container } from '@mantine/core'

import HeaderMain from 'components/UI/Headers/HeaderMain/HeaderMain'
import NavigationMain from 'components/UI/NavigationMain/NavigationMain'
import FooterMain from 'components/UI/FooterMain/FooterMain'
import HeaderPage from 'components/UI/Headers/HeaderPage/HeaderPage'
import CardsContainer from 'components/UI/Containers/CardsContainer/CardsContainer'
import TextFeatureBlock from 'components/UI/Blocks/TextFeatureBlock/TextFeatureBlock'
import PageLoader from 'components/UI/PageLoader/PageLoader'

import styles from './styles.module.scss'
const GenericScreen = ({
    pageData,
    projectAuthNumber,
    instanceCountryCode,
}) => {
    const { colorScheme } = useSelector((state) => state.settings)
    if (!pageData) {
        return <PageLoader />
    } else {
        const { data, referencedPosts } = pageData
        return (
            <div className={styles.container}>
                <div className={styles.layout}>
                    <HeaderMain />
                    {/* <NavigationMain /> */}
                    <main>
                        <HeaderPage
                            title={data.title}
                            subtitle={data.summary}
                            theme='themeAmber'
                        />
                        <Container size='1280px' px={0} py='2x'>
                            {parse(data.description || '')}
                        </Container>
                        {/* <Container size='1280px' px={0} py='2x'>
                            <BackgroundImage
                                src={data.media.coverImage.src}
                                sx={{
                                    aspectRatio:
                                        data.media.coverImage.aspectRatio,
                                }}
                            />
                        </Container>
                        <CardsContainer
                            aspectRatio='16/9'
                            cols={3}
                            component={<TextFeatureBlock />}
                            items={data.content.pages}
                        /> */}
                    </main>
                    <FooterMain />
                </div>
            </div>
        )
    }
}

export default GenericScreen
