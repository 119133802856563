import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Flex, Container, Grid } from '@mantine/core'
import styles from './styles.module.scss'
const CardsContainer = ({
    aspectRatio,
    bg,
    cols,
    component,
    glutter,
    items,
    data,
}) => {
    return (
        <Flex bg={bg} sx={{ width: '100%' }}>
            <Container className={styles.container}>
                <Grid cols={cols} grow spacing={glutter || 'md'}>
                    {items &&
                        items.map((item) => (
                            <Grid.Col key={uuidv4()} span={3}>
                                {React.cloneElement(component, {
                                    ...item,
                                    aspectRatio,
                                })}
                            </Grid.Col>
                        ))}
                </Grid>
            </Container>
        </Flex>
    )
}
export default CardsContainer

/*
<Flex justify='center' align='center' bg={bg} w='100%' py='2x'></Flex>
*/
