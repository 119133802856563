const navLinkTheme = (theme) => {
    const breadcrumb = {
        root: {
            width: 'auto',
            padding: 0,
            color: '#444',
            '&:hover': {
                backgroundColor: 'unset',
                color: theme.colors.purple[0],
            },
            '&[data-active]': {
                backgroundColor: 'unset',
                fontWeight: 'bold',
                '&:hover': { backgroundColor: 'unset' },
            },
        },
        body: { padding: '.25rem 0', backgroundColor: 'unset' },
        label: { fontSize: theme.fontSizes.md },
    }
    const footer = {
        root: {
            padding: 0,
            color: theme.colors.darkGrey[0],
            '&:hover': {
                backgroundColor: 'unset',
                color: theme.colors.purple[0],
            },
            '&[data-active]': {
                backgroundColor: 'unset',
                fontWeight: 'bold',
                '&:hover': { backgroundColor: 'unset' },
            },
        },
        body: { padding: '.25rem 0', backgroundColor: 'unset' },
        label: { fontSize: theme.fontSizes.sm },
    }
    const imageFeatureBlock = {
        root: {
            marginTop: theme.spacing.xl,
            padding: 0,
            color: theme.colors.purple[0],
            '&:hover': {
                backgroundColor: 'unset',
                color: theme.colors.purple[0],
                fontWeight: 'bold',
            },
            '&[data-active]': {
                backgroundColor: 'unset',
                fontWeight: 'bold',
                '&:hover': { backgroundColor: 'unset' },
            },
        },
        body: { padding: '.25rem 0', backgroundColor: 'unset' },
        label: { fontSize: theme.fontSizes.md },
    }
    const textFeatureBlock = {
        root: {
            color: theme.colors.purple[0],
            fontSize: theme.fontSizes.md,
            marginTop: theme.spacing.xl,
            padding: 0,
            '&:hover': {
                backgroundColor: 'unset',
                color: theme.colors.purple[0],
                fontWeight: 'bold',
            },
            '&[data-active]': {
                backgroundColor: 'unset',
                fontWeight: 'bold',
                '&:hover': { backgroundColor: 'unset' },
            },
        },
        body: { padding: '.25rem 0', backgroundColor: 'unset' },
        label: { fontSize: theme.fontSizes.md },
    }
    return {
        breadcrumb,
        footer,
        imageFeatureBlock,
        textFeatureBlock,
    }
}
export default navLinkTheme
