//# Settings reducer
import { SETTINGS_SET_COLOR_SCHEME, SETTINGS_SET_FONT_SIZE } from '../constants/settings'

const initialState = {
    colorScheme: 'light',
    globalFontSize: 'md',
}

export const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SETTINGS_SET_COLOR_SCHEME:
            return {
                ...state,
                colorScheme: action.payload,
            }
        case SETTINGS_SET_FONT_SIZE:
            return {
                ...state,
                globalFontSize: action.payload,
            }
        default:
            return state
    }
}
