import React from 'react'
import { Container } from '@mantine/core'
import parse from 'html-react-parser'
import FooterMain from 'components/UI/FooterMain/FooterMain'
import FormInformedConsent from 'components/UI/Forms/FormInformedConsent/FormInformedConsent'
import HeaderMain from 'components/UI/Headers/HeaderMain/HeaderMain'
import HeaderPage from 'components/UI/Headers/HeaderPage/HeaderPage'
import PageLoader from 'components/UI/PageLoader/PageLoader'
import styles from './styles.module.scss'
const InformedConsent = ({
    pageData,
    projectAuthNumber,
    instanceCountryCode,
}) => {
    if (!pageData) {
        return <PageLoader />
    } else {
        const {
            data,
            referencedProducts,
            referencedTherapeuticIndications,
            referencedProfessionalSpecialties,
        } = pageData
        return (
            <div className={styles.container}>
                <div className={styles.layout}>
                    <HeaderMain />
                    <main>
                        <HeaderPage
                            title={data.title}
                            summary={data.summary}
                            theme='themeLightChocolateWash'
                        />
                        <Container size='1280px' px={0} py='2x'>
                            <FormInformedConsent
                                referencedProducts={referencedProducts}
                                referencedTherapeuticIndications={
                                    referencedTherapeuticIndications
                                }
                                referencedProfessionalSpecialties={
                                    referencedProfessionalSpecialties
                                }
                            />
                        </Container>
                    </main>
                    <FooterMain />
                </div>
            </div>
        )
    }
}

export default InformedConsent
