import React, { useState } from 'react'
import { useMantineTheme } from '@mantine/core'

import HeaderMain from 'components/UI/Headers/HeaderMain/HeaderMain'
import NavigationMain from 'components/UI/NavigationMain/NavigationMain'
import Hero from 'components/UI/Hero/Hero'
import FooterMain from 'components/UI/FooterMain/FooterMain'
import CardsContainer from 'components/UI/Containers/CardsContainer/CardsContainer'
import StoryCard from 'components/UI/Cards/StoryCard/StoryCard'
import useScreen from 'hooks/useScreen'
//import useHome from './hooks/useHome'
import styles from './styles.module.scss'
import PageLoader from 'components/UI/PageLoader/PageLoader'
const Home = () => {
    const { pageData } = useScreen()
    const theme = useMantineTheme()
    const countryCode = 'MX'
    if (pageData === null) {
        return <PageLoader />
    } else {
        const { data, referencedPages } = pageData
        return (
            <React.Fragment>
                <div className={styles.container}>
                    <div className={styles.layout}>
                        <HeaderMain />
                        <NavigationMain />
                        <Hero
                            aspectRatio={data.media.heroImage.aspectRatio}
                            imageSource={data.media.heroImage.src}
                        />
                        <main>
                            {/* Featured Screens */}
                            <CardsContainer
                                aspectRatio='1/1'
                                bg={theme.colors.lightGrey[0]}
                                cols={4}
                                component={<StoryCard />}
                                items={referencedPages[0].items}
                            />
                            {/* Featured Links*/}
                            <CardsContainer
                                aspectRatio='16/9'
                                cols={countryCode === 'ar' ? 2 : 3}
                                component={<StoryCard />}
                                glutter='2x'
                                items={referencedPages[1].items}
                            />
                        </main>
                        <FooterMain />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Home
