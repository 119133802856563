import { generateAlphaColorPalette, setGlobalFontSize } from 'utils/utils'
export const mantineTheme = (colorScheme, globalFontSize) => {
    return {
        breakpoints: {
            xs: '30rem',
            sm: '48rem',
            md: '64rem',
            lg: '80rem',
            xl: '100rem',
        },
        colorScheme: 'colorScheme',
        colors: {
            almond: generateAlphaColorPalette(
                colorScheme === 'light' ? '223,203,195' : '75,60,54',
                10
            ),
            amber: generateAlphaColorPalette(
                colorScheme === 'light' ? '255,236,205' : '49,37,13',
                10
            ),
            darkGrey: generateAlphaColorPalette(
                colorScheme === 'light' ? '89,84,84' : '186,180,180',
                10
            ),
            lightChocolateWash: generateAlphaColorPalette(
                colorScheme === 'light' ? '246,244,243' : '36,35,34',
                10
            ),
            lightGrey: generateAlphaColorPalette(
                colorScheme === 'light' ? '238,231,231' : '48,42,42',
                10
            ),
            mediumGrey: generateAlphaColorPalette(
                colorScheme === 'light' ? '166,159,159' : '166,159,159',
                10
            ),
            olive: generateAlphaColorPalette(
                colorScheme === 'light' ? '234,213,201' : '68,52,43',
                10
            ),
            peach: generateAlphaColorPalette(
                colorScheme === 'light' ? '254,220,202' : '66,42,29',
                10
            ),
            purple: generateAlphaColorPalette(
                colorScheme === 'light' ? '190,43,187' : '225,10,220',
                10
            ),
            white: generateAlphaColorPalette(
                colorScheme === 'light' ? '255,255,255' : '18,18,18',
                10
            ),
            black: generateAlphaColorPalette(
                colorScheme === 'light' ? '120,120,120' : '18,18,18',
                10
            ),
            danger: generateAlphaColorPalette(
                colorScheme === 'light' ? '240,0,0' : '240,0,0',
                10
            ),
        },
        defaultGlutter: '8x',
        defaultRadius: '2xs',
        fontFamily: 'BMSHumanity-Regular, sans-serif',
        fontSizes: {
            xs: '.75rem',
            sm: '.875rem',
            md: '1rem',
            lg: '1.25rem',
            xl: '1.5rem',
            '2x': '2rem',
            '3x': '3rem',
            '4x': '4rem',
            global: setGlobalFontSize(globalFontSize),
        },
        primaryColor: 'purple',
        primaryShade: 0,
        spacing: {
            '2xs': '.25rem',
            xs: '.5rem',
            sm: '.75rem',
            md: '1rem',
            lg: '1.25rem',
            xl: '1.5rem',
            '2xl': '1.75rem',
            '2x': '2rem',
            '3x': '3rem',
            '4x': '4rem',
            '8x': '8rem',
        },
    }
}
