import React from 'react'
import PropTypes from 'prop-types'
const instanceCode = process.env.REACT_APP_COUNTRY_CODE

const logos = {
    bmsLogo: {
        light: `https://bmspspbucket.s3.amazonaws.com/${instanceCode}/images/bms-logo-light.svg`,
        dark: `https://bmspspbucket.s3.amazonaws.com/${instanceCode}/images/bms-logo-dark.svg`,
    },
    programLogo: {
        light: `https://bmspspbucket.s3.amazonaws.com/${instanceCode}/images/bms-psp-program-logo-light.svg`,
        dark: `https://bmspspbucket.s3.amazonaws.com/${instanceCode}/images/bms-psp-program-logo-light.svg`,
    },
}

const Logo = ({ logo, theme }) => {
    const src = logos[logo][theme]
    return (
        <React.Fragment>
            <img src={src} alt='Logo' />
        </React.Fragment>
    )
}

export default Logo

Logo.defaultProps = {
    url: '/',
    variant: 'main',
}

Logo.propTypes = {
    url: PropTypes.string,
    variant: PropTypes.string,
    //variant: PropTypes.oneOf(propTypesVariants),
}
