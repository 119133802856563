import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { getPageService } from 'services/getPageService'
import { getPostService } from 'services/getPostsService'

const useScreen = () => {
    const instance = process.env.REACT_APP_INSTANCE
    const countryCode = process.env.REACT_APP_COUNTRY_CODE
    const themes = ['almond', 'amber', 'lightChocolateWash', 'olive', 'peach']
    const { url1, url2, url3 } = useParams()
    const [loading, setLoading] = useState(true)
    const [fetchedData, setFetchedData] = useState(null)

    const getCurrentPage = () => {
        if (url3) return url3
        if (url2) return url2
        return url1
    }

    const { pid, tiid } = useSelector((state) => state.auth.user)
    const currentPage = getCurrentPage()

    useEffect(() => {
        if (loading) {
            const getPageData = async () => {
                if (url2 === undefined) {
                    const pageData = await getPageService(currentPage)
                    setFetchedData(pageData)
                    return
                } else {
                    const pageData = await getPostService(currentPage)
                    setFetchedData(pageData)
                    return
                }
            }
            getPageData()
            setLoading(false)
        }
    }, [url1, url2, url3])

    return {
        instance,
        countryCode,
        themes,
        pageData: fetchedData,
    }
}

export default useScreen
