import React from 'react'
import parse from 'html-react-parser'
import { BackgroundImage, Container } from '@mantine/core'
import HeaderMain from 'components/UI/Headers/HeaderMain/HeaderMain'
import NavigationMain from 'components/UI/NavigationMain/NavigationMain'
import FooterMain from 'components/UI/FooterMain/FooterMain'
import HeaderPage from 'components/UI/Headers/HeaderPage/HeaderPage'
import CardsContainer from 'components/UI/Containers/CardsContainer/CardsContainer'
import ContactCard from 'components/UI/Cards/ContactCard/ContactCard'
import SubjectBlock from 'components/UI/Blocks/SubjectBlock/SubjectBlock'
//import useContactInformation from './hooks/useContactInformation'
import useScreen from 'hooks/useScreen'
import PageLoader from 'components/UI/PageLoader/PageLoader'
import styles from './styles.module.scss'
const ContactInformation = () => {
    const { instance, themes, pageData } = useScreen()
    console.log('🚀 ~ ContactInformation ~ pageData:', pageData)
    /*
    const { pageData, infoCards } = useContactInformation()
    */
    if (!pageData) {
        return <PageLoader />
    } else {
        const { data, referencedPosts } = pageData
        const infoCards = referencedPosts[0].items
        return (
            <div className={styles.container}>
                <div className={styles.layout}>
                    <HeaderMain />
                    <NavigationMain />
                    <main>
                        <HeaderPage
                            title={data.title}
                            summary={data.summary}
                            theme='themeAmber'
                        />
                        <Container size='1280px' px={0} py='2x'>
                            <BackgroundImage
                                src={data.media.coverImage.src}
                                sx={{
                                    aspectRatio: '3/1',
                                }}
                            />
                        </Container>
                        <CardsContainer
                            aspectRatio='5/4'
                            cols={4}
                            component={<ContactCard />}
                            items={infoCards}
                        />
                    </main>
                    <FooterMain />
                </div>
            </div>
        )
    }
}

export default ContactInformation
