import { Link } from 'react-router-dom'
import { Breadcrumbs, Container, Flex, NavLink } from '@mantine/core'
import { useMantineTheme } from '@mantine/core'
import navLinkTheme from 'assets/themes/navLinkTheme'
import styles from './styles.module.scss'
import useBreadcrums from '../hooks/useBreadcrums'

const BreadcrumbsMain = () => {
    const { breadcrumbsData } = useBreadcrums()
    const theme = useMantineTheme()
    const { breadcrumb } = navLinkTheme(theme)
    return (
        <Flex sx={{ width: '100%' }}>
            <Container className={styles.container}>
                <Breadcrumbs>
                    {breadcrumbsData.map((item, index) => (
                        <NavLink
                            active={item.active}
                            component={Link}
                            key={index}
                            label={item.title}
                            styles={breadcrumb}
                            to={item.url}
                        />
                    ))}
                </Breadcrumbs>
            </Container>
        </Flex>
    )
}
export default BreadcrumbsMain
