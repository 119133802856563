import React from 'react'
//import PropTypes from 'prop-types'
import PageLoader from 'components/UI/PageLoader/PageLoader'
import { usePublicPage } from './hooks/usePublicPage'

const PublicPage = () => {
    const { template, pageData, projectAuthNumber, instanceCountryCode } =
        usePublicPage()
    return !pageData ? (
        <PageLoader />
    ) : (
        <React.Fragment>
            {React.cloneElement(template, {
                pageData,
                projectAuthNumber,
                instanceCountryCode,
            })}
        </React.Fragment>
    )
}

export default PublicPage
