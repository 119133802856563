import React from 'react'
import {
    Avatar,
    BackgroundImage,
    Box,
    Container,
    Flex,
    Grid,
    Text,
} from '@mantine/core'
import parse from 'html-react-parser'

import HeaderMain from 'components/UI/Headers/HeaderMain/HeaderMain'
import NavigationMain from 'components/UI/NavigationMain/NavigationMain'
import FooterMain from 'components/UI/FooterMain/FooterMain'
import HeaderPage from 'components/UI/Headers/HeaderPage/HeaderPage'
import BreadcrumbsMain from 'components/UI/Breadcrums/BreadcrumsMain/BreadcrumsMain'
import useScreen from 'hooks/useScreen'
import styles from './styles.module.scss'
import PageLoader from 'components/UI/PageLoader/PageLoader'
const AboutTheProgram = () => {
    const { instance, themes, pageData } = useScreen()
    if (!pageData) {
        return <PageLoader />
    } else {
        const { data } = pageData
        return (
            <div className={styles.container}>
                <div className={styles.layout}>
                    <HeaderMain />
                    <NavigationMain />
                    <main>
                        <HeaderPage
                            title={data.title}
                            summary={data.summary}
                            theme='themeAmber'
                        />
                        <BreadcrumbsMain />
                        <Container size='1280px' px={0} py='2x'>
                            <BackgroundImage
                                src={data.media.heroImage.src}
                                sx={{
                                    aspectRatio: '4/1',
                                }}
                            />
                        </Container>
                        <Container size='1280px' px={0} py='2x'>
                            <Grid gutter='2x'>
                                {pageData.posts &&
                                    pageData.posts.map((service, index) => (
                                        <Grid.Col
                                            md={4}
                                            lg={
                                                ('ar', 'ch').includes(instance)
                                                    ? 6
                                                    : 4
                                            }
                                            sx={(theme) => ({
                                                padding: theme.spacing.sm,
                                                backgroundColor:
                                                    theme.colors[
                                                        themes[
                                                            index > 4
                                                                ? index - 4
                                                                : index
                                                        ]
                                                    ][0],
                                            })}
                                            key={service._id}
                                        >
                                            <Flex gap='sm'>
                                                <Avatar
                                                    radius='sm'
                                                    src={
                                                        service.data.media
                                                            .coverImage.src
                                                    }
                                                    size='xl'
                                                />
                                                <Box>
                                                    <Text color='purple.0'>
                                                        {service.data.name}
                                                    </Text>
                                                    <Text
                                                        span
                                                        color='darkGrey.0'
                                                    >
                                                        {
                                                            service.data
                                                                .description
                                                        }
                                                    </Text>
                                                </Box>
                                            </Flex>
                                        </Grid.Col>
                                    ))}
                            </Grid>
                        </Container>
                        {/* <CardsContainer
                        aspectRatio='1/1'
                        cols={3}
                        component={<StoryCard />}
                        items={featuredPages}
                    /> */}
                    </main>
                    <FooterMain />
                </div>
            </div>
        )
    }
}

export default AboutTheProgram
