import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { authLogin } from 'store/actions/auth'
import { showNotifications } from 'utils/showNotifications'

const useFormLogin = () => {
    const instanceCountryCode = process.env.REACT_APP_COUNTRY_CODE
    const dispatch = useDispatch()
    const [isFetching, setIsFetching] = useState(false)
    const [activeTab, setActiveTab] = useState('patients')
    const [formState, setFormState] = useState({
        username: '',
        password: '',
        terms: false,
        errors: [],
    })

    //* Handle Input Change
    const handleInputChange = (name, value) => {
        const getInputError = (name, value) => {
            if (value === '') {
                return {
                    name: name,
                    message: `Debe completar este campo para continuar.`,
                }
            }
            return null
        }
        const error = getInputError(name, value)
        setFormState({
            ...formState,
            [name]: value,
            errors: error
                ? [...formState.errors, error]
                : [...formState.errors.filter((input) => input.name !== name)],
        })
    }

    //* Handle Checkbox Change
    const handleCheckboxChange = (value) => {
        const getCheckboxError = (value) => {
            if (value === false) {
                return {
                    name: 'terms',
                    message: `Debe aceptar los terminos y condiciones del sitio web para continuar.`,
                }
            }
            return null
        }
        const error = getCheckboxError(value)
        setFormState({
            ...formState,
            terms: value,
            errors: error
                ? [...formState.errors, error]
                : [
                      ...formState.errors.filter(
                          (input) => input.name !== 'terms'
                      ),
                  ],
        })
    }

    //* Handle Submit
    const handleSubmit = async (e) => {
        setIsFetching(true)
        e.preventDefault()
        if (
            //formState.errors.length ||
            formState.username === '' ||
            formState.password === ''
        ) {
            showNotifications({
                type: 'danger',
                title: 'Error',
                message:
                    'Debe completar los campos requeridos para iniciar sesión',
            })
            setIsFetching(false)
        } else if (formState.terms === false) {
            showNotifications({
                type: 'danger',
                title: 'Error',
                message:
                    'Debe aceptar los terminos y condiciones del sitio web para continuar.',
            })
        } else {
            dispatch(
                authLogin({
                    username: formState.username,
                    password: formState.password,
                })
            )
            setIsFetching(false)
        }
    }

    return {
        activeTab,
        instanceCountryCode,
        isFetching,
        setActiveTab,
        handleCheckboxChange,
        handleInputChange,
        handleSubmit,
    }
}

export default useFormLogin
