/* import { posts } from '../../../../data/appData'
import { useParams } from 'react-router-dom'
import { getBreadcrumsData, getPageData } from 'utils/utils' */
import { posts } from '../../../../data/appData'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getBreadcrumsData, getPageData } from 'utils/utils'
const useWhatDoesTheProgramOffers = () => {
    /* const params = useParams()
    const breadcrumbsData = getBreadcrumsData(params, posts)
    const pageData = getPageData(posts, params)
    const featuredArticles = posts
        .filter((article) =>
            pageData.content.featuredArticles.includes(article.url)
        )
        .map((article) => {
            return {
                title: article.title,
                summary: article.summary,
                imageSource: article.media.coverImage.src,
                linkLabel: null,
                linkUrl: `${article.url}`,
                orientation: 'horizontal',
            }
        })
    return { breadcrumbsData, pageData, featuredArticles } */
    const instance = process.env.REACT_APP_INSTANCE
    const params = useParams()
    const { url1, url2 } = params
    const { pages } = useSelector((state) => state.pages)
    const breadcrumbsData = getBreadcrumsData(params, posts)
    const pageData = getPageData(pages, url2 ? url2 : url1)
    const themes = ['almond', 'amber', 'lightChocolateWash', 'olive', 'peach']

    return {
        instance,
        breadcrumbsData,
        pageData: pageData ? pageData.data : null,
        articles: pageData ? pageData.data.content.articles : null,
        themes,
    }
}
export default useWhatDoesTheProgramOffers
