const tabsTheme = (theme) => {
    return {
        loginForm: {
            root: {
                width: '100%',
            },
            tabsList: {
                display: 'flex',
                flexDirection: 'row',
                gap: '.25rem',
            },
            tab: {
                borderRadius: '.5rem .5rem 0 0',
                backgroundColor: theme.colors.mediumGrey[0],
                color: theme.colors.white[0],
                width: 'calc(50% - .125rem)',
                paddingBlock: theme.spacing.sm,
                borderBottom: '0px',
                '&:hover': {
                    backgroundColor: theme.colors.mediumGrey[4],
                    color: theme.colors.purple[0],
                },
                '&[data-active]': {
                    color: theme.colors.darkGrey[0],
                    backgroundColor: theme.colors.white[0],
                },
            },
        },
        faqContainer: {
            tabsList: {
                width: '20%',
            },
            tab: {
                backgroundColor: 'transparent',
                borderBottom: '0px',
                borderRadius: `${theme.spacing['2xs']} 0 0 ${theme.spacing['2xs']}`,
                color: theme.colors.darkGrey[0],
                fontSize: theme.fontSizes.lg,
                paddingBlock: theme.spacing.md,
                '&:hover': {
                    backgroundColor: theme.colors.lightGrey[5],
                    color: theme.colors.purple[0],
                },
                '&[data-active]': {
                    backgroundColor: theme.colors.purple[9],
                    borderRightColor: theme.colors.purple[0],
                    borderRightStyle: 'solid',
                    borderRightWidth: theme.spacing['2xs'],
                    color: theme.colors.purple[0],
                },
            },
        },
    }
}

export default tabsTheme
