import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { authValidate } from 'store/actions/auth'
import { getValidationToken, kebabCaseToCamelCase } from 'utils/utils'

// Private Templates
import AboutMyDisease from 'components/screens/AboutMyDisease/AboutMyDisease'
import AboutMyTreatment from 'components/screens/AboutMyTreatment/AboutMyTreatment'
import AboutTheProgram from 'components/screens/AboutTheProgram/AboutTheProgram'
import ArticlesAndBrochures from 'components/screens/ArticlesAndBrochures/ArticlesAndBrochures'
import ArticlesAndMaterials from 'components/screens/ArticlesAndMaterials/ArticlesAndMaterials'
import ContactInformation from 'components/screens/ContactInformation/ContactInformation'
import FrequentlyAskedQuestions from 'components/screens/FrequentlyAskedQuestions/FrequentlyAskedQuestions'
import Home from 'components/screens/Home/Home'
import MyMonitor from 'components/screens/MyMonitor/MyMonitor'
import Profile from 'components/screens/Profile/Profile'
import SitesOfInterest from 'components/screens/SitesOfInterest/SitesOfInterest'
import TermsAndConditions from 'components/screens/TermsAndConditions/TermsAndConditions'
import Videos from 'components/screens/Videos/Videos'
import WhatDoesTheProgramOffers from 'components/screens/WhatDoesTheProgramOffers/WhatDoesTheProgramOffers'
import Post from 'components/screens/Post/Post'

const templates = {
    articulosYFolletos: <ArticlesAndBrochures />,
    articulosYMateriales: <ArticlesAndMaterials />,
    datosDeContacto: <ContactInformation />,
    inicio: <Home />,
    miMonitora: <MyMonitor />,
    post: <Post />,
    preguntasFrecuentes: <FrequentlyAskedQuestions />,
    miPerfil: <Profile />,
    queOfreceElPrograma: <WhatDoesTheProgramOffers />,
    sitiosDeInteres: <SitesOfInterest />,
    sobreElPrograma: <AboutTheProgram />,
    sobreMiEnfermedad: <AboutMyDisease />,
    sobreMiTratamiento: <AboutMyTreatment />,
    terminosYCondiciones: <TermsAndConditions />,
    videos: <Videos />,
}

const instanceCountryCode = process.env.REACT_APP_COUNTRY_CODE

export const usePrivatePage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isLogged = useSelector((state) => state.auth)
    const validationToken = getValidationToken()

    useEffect(() => {
        if (isLogged.token === null) {
            if (validationToken !== null) {
                dispatch(authValidate())
            } else {
                navigate('/iniciar-sesion', { replace: true })
            }
        }
    }, [dispatch, navigate, validationToken, isLogged])

    const { url1, url2, url3 } = useParams()
    const getCurrentPage = () => {
        if (url3) return 'post'
        if (
            url2 &&
            ['sobre-mi-enfermedad', 'sobre-mi-tratamiento'].includes(url1)
        )
            return 'post'
        if (url2) return url2
        return url1
    }
    const currentPage = getCurrentPage()

    const template = currentPage
        ? templates[kebabCaseToCamelCase(currentPage)]
        : templates['inicio']

    return {
        isLogged: isLogged.token,
        template,
        instanceCountryCode,
    }
}
