const rootStyles = {
    padding: 0,
}

export const navLinkMainTheme = (theme) => {
    return {
        root: {
            ...rootStyles,
            color: theme.colors.darkGrey[0],
            '&:hover': {
                backgroundColor: 'unset',
                color: theme.colors.purple[0],
            },
            '&[data-active]': {
                backgroundColor: 'unset',
                color: theme.colors.purple[0],
                fontWeight: 'bold',
                '&:hover': { backgroundColor: 'unset' },
            },
        },
        body: { padding: '.25rem 0', backgroundColor: 'unset' },
        label: { fontSize: theme.fontSizes.sm },
    }
}

export const navLinkLoginTheme = (theme) => {
    return {
        root: {
            ...rootStyles,
            backgroundColor: theme.colors.purple[0],
            borderColor: theme.colors.white[1],
            borderStyle: 'solid',
            borderWidth: '1px',
            color: theme.colors.white[0],
            textAlign: 'center',
            '&:hover': {
                backgroundColor: theme.colors.white[1],
                borderColor: theme.colors.purple[1],
                borderStyle: 'solid',
                borderWidth: '1px',
                color: theme.colors.purple[0],
            },
        },
        body: { padding: '.25rem 0', backgroundColor: 'unset' },
        label: { fontSize: theme.fontSizes.sm },
    }
}
